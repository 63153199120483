import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ItemListModal = ({
  open,
  handleClose,
  newStockItems,
  removeItem,
  handleAddStock,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%' },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          pl: 2,
          pr: 2,
          overflowY: 'auto',
          maxHeight: '90vh',
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: -1,
            bgcolor: 'background.paper',
            zIndex: 1,
            p: 2,
          }}
        >
          <Typography variant="h6" component="h3" gutterBottom>
            Itens a serem cadastrados:
          </Typography>
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            color="primary"
            onClick={handleAddStock}
            fullWidth
          >
            Cadastrar Todos os Itens
          </Button>
        </Box>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          {newStockItems.map((item, index) => (
            <Grid item xs={12} sm={12} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {item.nameProduct}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Categoria: {item.category}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Quantidade: {item.qtd} {item.qtdUnit}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Volume: {item.volume} {item.volumeUnit}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Preço de Compra: R${' '}
                    {parseFloat(item.purchasePrice).toFixed(2)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Preço de Venda: R${' '}
                    {parseFloat(item.sellingPrice).toFixed(2)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Observações: {item.observations}
                  </Typography>
                  <Box sx={{ marginTop: 1 }}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export default ItemListModal;
