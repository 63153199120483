import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { usePermissions } from '../../../contexts/Permissions/PermissionsContext';

const GroupTable = ({ groups, onEdit, onDelete }) => {
  const { permissions } = usePermissions();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nome do Grupo</TableCell>
          <TableCell>Ações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {groups.map((group) => (
          <TableRow key={group._id}>
            <TableCell>{group.name}</TableCell>
            <TableCell>
              {permissions?.userGroups?.edit && (
                <IconButton onClick={() => onEdit(group)}>
                  <EditIcon />
                </IconButton>
              )}
              {permissions?.userGroups?.delete && (
                <IconButton onClick={() => onDelete(group._id)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GroupTable;
