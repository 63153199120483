import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

const PartySelector = ({
  parties,
  selectedParty,
  handleInputChange,
  handleAddPartyCard,
  handleRemovePartyCard,
  handleRemoveAllPartyCards,
}) => {
  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="select-party-label">Selecione uma festa</InputLabel>
        <Select
          labelId="select-party-label"
          label="Selecione uma festa"
          value={selectedParty}
          onChange={handleInputChange}
        >
          {parties.map((party) => (
            <MenuItem key={party._id} value={party.partyId}>
              {party.nameParty} - {party.partyId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddPartyCard}
          >
            Adicionar
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<RemoveIcon />}
            onClick={() => handleRemovePartyCard(selectedParty)}
          >
            Remover
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleRemoveAllPartyCards}
          >
            Remover Todas
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PartySelector;
