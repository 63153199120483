import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ClassIcon from '@mui/icons-material/Class';

import { Link } from 'react-router-dom';
import { usePermissions } from '../../../contexts/Permissions/PermissionsContext';

const MainListItems = () => {
  const { permissions } = usePermissions();

  return (
    <div>
      {permissions.home?.view && (
        <ListItem button component={Link} to="/">
          <ListItemIcon sx={{ mr: -3 }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      )}

      {permissions.parties?.view && (
        <ListItem button component={Link} to="/partys">
          <ListItemIcon sx={{ mr: -3 }}>
            <CelebrationIcon />
          </ListItemIcon>
          <ListItemText primary="Festas" />
        </ListItem>
      )}

      {permissions.userGroups?.view && (
        <ListItem button component={Link} to="/userManagement">
          <ListItemIcon sx={{ mr: -3 }}>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItem>
      )}

      {permissions.cards?.view && (
        <ListItem button component={Link} to="/walletManager">
          <ListItemIcon sx={{ mr: -3 }}>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary="Carteira" />
        </ListItem>
      )}

      {permissions.categories?.view && (
        <ListItem button component={Link} to="/categoryManager">
          <ListItemIcon sx={{ mr: -3 }}>
            <ClassIcon />
          </ListItemIcon>
          <ListItemText primary="Categorias" />
        </ListItem>
      )}

      <ListItem button component={Link} to="/logout">
        <ListItemIcon sx={{ mr: -3 }}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItem>
    </div>
  );
};

export default MainListItems;
