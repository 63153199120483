import React from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Grid,
  Chip,
} from '@mui/material';
import { BlinkingDot } from '../../../utils/global';

const PartyCard = ({ party, loading, countdown }) => {
  return (
    <Card sx={{ marginTop: 4 }}>
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              {party.nameParty} - {party.partyId}
            </Typography>
          </Grid>

          {party.partyEnded && (
            <Grid item>
              <Chip label="Finalizada" variant="outlined" color="primary" />
            </Grid>
          )}
          {!party.partyEnded && (
            <Grid item>
              <BlinkingDot />
            </Grid>
          )}
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography sx={{ marginTop: 2 }} variant="body1" gutterBottom>
              Total de Vendas: {party?.totalSoldItems}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Receita Total: R$ {party?.totalMoney?.toLocaleString('pt-BR')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Item Mais Vendido: {party?.mostSold?._id}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Item com Menor Estoque: {party?.lowestStock?.nameProduct}{' '}
              (Quantidade: {party?.lowestStock?.quantityRemaining})
            </Typography>
            {!party.partyEnded && (
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Atualização automática em: {countdown} segundos
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PartyCard;
