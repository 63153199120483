import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from '../../contexts/Permissions/PermissionsContext';
import { LoadFull } from '../../utils/global';

const RotaProtegida = ({ element, permissionsRequired }) => {
  const { permissions, loading, tokenLocal, isTokenValid } = usePermissions();

  useEffect(() => {
    isTokenValid();
  }, [isTokenValid]);

  if (loading) {
    return <LoadFull />;
  }

  const hasPermissions = permissionsRequired.every((permission) => {
    const [resource, action] = permission.split('.');
    return permissions[resource]?.[action];
  });

  if (!tokenLocal) {
    return <Navigate to="/login" />;
  }

  return hasPermissions ? element : <Navigate to="/" />;
};

export default RotaProtegida;
