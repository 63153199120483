import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Grid,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const EmployeeDashboard = ({ employee }) => {
  const [showBalance, setShowBalance] = useState(true);
  const totalEarnings = employee.partysJob.reduce(
    (acc, job) => (job.isPaid ? acc + (job.salary + job.extra) : acc),
    0,
  );
  const unpaidEarnings = employee.partysJob.reduce(
    (acc, job) => (!job.isPaid ? acc + (job.salary + job.extra) : acc),
    0,
  );

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">Saldo Total:</Typography>
            <IconButton onClick={() => setShowBalance(!showBalance)}>
              {showBalance ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>
          {showBalance && (
            <Typography variant="h6">
              R$ {totalEarnings.toLocaleString('pt-BR')}
            </Typography>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h6">Histórico de Festas</Typography>
          <List>
            {employee.partysJob.map(
              (job) =>
                job.isPaid && (
                  <ListItem key={job.partyId}>
                    <ListItemText
                      primary={`Festa: ${job.partyId}`}
                      secondary={`Ganho: R$ ${(job.salary + job.extra).toLocaleString('pt-BR')}`}
                    />
                  </ListItem>
                ),
            )}
          </List>
          <Typography variant="body1" gutterBottom>
            Valor ainda não pago: R$ {unpaidEarnings.toLocaleString('pt-BR')}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EmployeeDashboard;
