import axios from 'axios';

const getToken = () => localStorage.getItem('token');

const apiClient = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BACK_API,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${getToken()}`,
  },
});

/**
 * Cria uma nova festa.
 * @param {Object} partyData - Os dados da festa.
 * @returns {Promise<Object>} A resposta da criação da festa.
 */
export const createParty = async (partyData) => {
  const response = await apiClient.post('/party/create', partyData);
  return response.data;
};

/**
 * Edita uma festa existente.
 * @param {Object} partyData - Os dados atualizados da festa.
 * @returns {Promise<Object>} A resposta da edição da festa.
 */
export const editParty = async (partyData) => {
  const response = await apiClient.put('/party/edit', partyData);
  return response.data;
};

/**
 * Busca detalhes de uma festa pelo ID da festa.
 * @param {string} partyId - O ID da festa.
 * @returns {Promise<Object>} Os detalhes da festa.
 */
export const fetchPartyDetails = async (partyId) => {
  const response = await apiClient.get(`/party/searchPartId/?id=${partyId}`);
  return response.data;
};

/**
 * Busca detalhes de uma festa pelo ID da festa para Publico.
 * @param {string} partyId - O ID da festa.
 * @returns {Promise<Object>} Os detalhes da festa.
 */
export const fetchPartyDetailsPublic = async (partyId) => {
  const response = await apiClient.get(
    `/party/searchPartIdPublic/?id=${partyId}`,
  );
  return response.data;
};

/**
 * Busca o número total de itens vendidos para um determinado ID de festa.
 * @param {string} partyId - O ID da festa.
 * @returns {Promise<number>} O número total de itens vendidos.
 */
export const fetchTotalSoldItems = async (partyId) => {
  const response = await apiClient.get(`/party/searchSolds?id=${partyId}`);
  return response.data.solds;
};

/**
 * Busca os itens mais vendidos para um determinado ID de festa.
 * @param {string} partyId - O ID da festa.
 * @returns {Promise<Object>} Os itens mais vendidos.
 */
export const fetchMostSold = async (partyId) => {
  const response = await apiClient.get(`/party/mostSold?id=${partyId}`);
  return response.data;
};

/**
 * Busca os itens com menor estoque para um determinado ID de festa.
 * @param {string} partyId - O ID da festa.
 * @returns {Promise<Object>} Os itens com menor estoque.
 */
export const fetchLowestStock = async (partyId) => {
  const response = await apiClient.get(`/party/lowestStock?id=${partyId}`);
  return response.data;
};

/**
 * Busca todas as festas.
 * @returns {Promise<Object>} Uma lista de todas as festas.
 */
export const fetchParties = async () => {
  const response = await apiClient.get(`/party/searchParty`);
  return response.data;
};

/**
 * Faz o login de um usuário com nome de usuário e senha.
 * @param {string} username - O nome de usuário.
 * @param {string} password - A senha.
 * @returns {Promise<string>} O token de autorização.
 */
export const login = async (username, password) => {
  const response = await apiClient.post('/auth/login', { username, password });
  return response.headers.authorization;
};

/**
 * Busca itens vendidos para um determinado ID de festa.
 * @param {string} partyId - O ID da festa.
 * @returns {Promise<number>} O número total de itens vendidos.
 */
export const fetchSolds = async (partyId) => {
  const response = await apiClient.get(`/party/searchSolds?id=${partyId}`);
  return response.data.solds;
};

/**
 * Envia itens vendidos para o servidor.
 * @param {Object} saleItems - Os itens vendidos.
 * @returns {Promise<number>} O status da resposta.
 */
export const sendSaleItems = async (saleItems) => {
  const response = await apiClient.put('/party/soldItens', saleItems);
  return response.status;
};

/**
 * Busca itens de estoque para um determinado ID de festa.
 * @param {string} partyId - O ID da festa.
 * @returns {Promise<Object>} Os itens de estoque.
 */
export const fetchStock = async (partyId) => {
  const response = await apiClient.get(`/party/searchStock/${partyId}`);
  return response.data;
};

/**
 * Exclui um item de estoque para um determinado ID de festa e ID de produto.
 * @param {string} partyId - O ID da festa.
 * @param {string} productId - O ID do produto.
 * @returns {Promise<void>}
 */
export const deleteStockItem = async (partyId, productId) => {
  await apiClient.delete(`/party/${partyId}/stock/${productId}`);
};

/**
 * Adiciona um item de estoque.
 * @param {Object} data - Os dados do item de estoque.
 * @returns {Promise<void>}
 */
export const addStockItem = async (data) => {
  await apiClient.post('/party/addStock', data);
};

/**
 * Atualiza um item de estoque para um determinado ID de festa e ID de produto.
 * @param {string} partyId - O ID da festa.
 * @param {string} productId - O ID do produto.
 * @param {Object} data - Os dados atualizados do item de estoque.
 * @returns {Promise<void>}
 */
export const updateStockItem = async (partyId, productId, data) => {
  await apiClient.put(`/party/${partyId}/stock/${productId}`, data);
};

/**
 * Reseta a senha de um usuário usando um token.
 * @param {string} token - O token de redefinição.
 * @param {string} password - A nova senha.
 * @returns {Promise<void>}
 */
export const resetPassword = async (token, password) => {
  await apiClient.post(
    '/auth/reset-password',
    { token, password },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

/**
 * Gera um token de redefinição de senha para um determinado email.
 * @param {string} email - O email do usuário.
 * @returns {Promise<void>}
 */
export const generateResetToken = async (email) => {
  await apiClient.post(
    '/auth/generate-reset-token',
    { email },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

/**
 * Busca permissões para um determinado ID de grupo.
 * @param {string} groupId - O ID do grupo.
 * @returns {Promise<Object>} As permissões do grupo.
 */
export const fetchPermissions = async (groupId) => {
  const token = getToken();
  if (!token) throw new Error('Token not found');

  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  const response = await apiClient.get(
    `/auth/groups/${groupId}/permissions`,
    config,
  );
  return response.data.permissions;
};

/**
 * Busca as coordenadas de um local usando a API OpenCageData.
 * @param {string} location - O nome do local.
 * @returns {Promise<Object>} As coordenadas do local.
 */
export const fetchCoordinates = async (location) => {
  const response = await axios.get(
    'https://api.opencagedata.com/geocode/v1/json',
    {
      params: {
        q: location,
        key: '7bc93777e0e64f03a6e882c7484b2882',
      },
    },
  );
  return response.data.results[0].geometry;
};

/**
 * Busca a temperatura atual de um local usando a API Open-Meteo.
 * @param {number} latitude - A latitude do local.
 * @param {number} longitude - A longitude do local.
 * @returns {Promise<number>} A temperatura atual do local.
 */
export const fetchTemperature = async (latitude, longitude) => {
  const response = await axios.get('https://api.open-meteo.com/v1/forecast', {
    params: {
      latitude,
      longitude,
      current_weather: true,
    },
  });
  return response.data.current_weather.temperature;
};

/**
 * Fetches the list of employees in the organization.
 * @returns {Promise<Array>} A list of employees.
 */
export const fetchEmployees = async () => {
  const response = await apiClient.get('/user/profile/ListEmployeesOrg');
  // Ensure the data is returned as an array
  return response.data || [];
};

/**
 * Busca o ususario logado
 * @returns {Promise<Array>} A list of employees.
 */
export const fetchEmployeesLogged = async () => {
  const response = await apiClient.get('/user/profile/getListEmployeLogged');
  return response.data || [];
};

/**
 * Registers a new user.
 * @param {Object} userData - The user data.
 * @returns {Promise<Object>} The registered user data.
 */
export const registerUser = async (userData) => {
  const response = await apiClient.post('/auth/register', userData);
  return response.data;
};

/**
 * Fetches the list of groups.
 * @returns {Promise<Array>} A list of groups.
 */
export const fetchGroups = async () => {
  const response = await apiClient.get('/auth/groups');
  return response.data || [];
};

/**
 * Deletes a user.
 * @param {string} userId - The ID of the user to delete.
 * @returns {Promise<void>}
 */
export const deleteUser = async (userId) => {
  await apiClient.delete(`/auth/user/${userId}`);
};

/**
 * Edita um usuário.
 * @param {string} userId - O ID do usuário a ser editado.
 * @param {Object} userData - Os dados atualizados do usuário.
 * @returns {Promise<Object>} Os dados do usuário atualizado.
 */
export const editUser = async (userId, userData) => {
  const response = await apiClient.put(`/auth/editUser/${userId}`, userData);
  return response.data;
};

/**
 * Creates a new group.
 * @param {Object} groupData - The group data.
 * @returns {Promise<Object>} The created group data.
 */
export const createGroup = async (groupData) => {
  const response = await apiClient.post('/auth/groups', groupData);
  return response.data;
};

/**
 * Edits a group.
 * @param {string} groupId - The ID of the group to edit.
 * @param {Object} groupData - The updated group data.
 * @returns {Promise<Object>} The updated group data.
 */
export const editGroup = async (groupId, groupData) => {
  const response = await apiClient.put(`/auth/group/${groupId}`, groupData);
  return response.data;
};

/**
 * Deletes a group.
 * @param {string} groupId - The ID of the group to delete.
 * @returns {Promise<void>}
 */
export const deleteGroup = async (groupId) => {
  await apiClient.delete(`/auth/group/${groupId}`);
};

/**
 * Adiciona um funcionário a uma festa.
 * @param {Object} employeeData - Os dados do funcionário e da festa.
 * @returns {Promise<Object>} A resposta da adição do funcionário.
 */
export const addEmployees = async (employeeData) => {
  const response = await apiClient.post('/party/addEmployees', employeeData);
  return response.data;
};

/**
 * Remove um funcionário de uma festa.
 * @param {Object} employeeData - Os dados do funcionário e da festa.
 * @returns {Promise<Object>} A resposta da remoção do funcionário.
 */
export const removeEmployee = async (employeeData) => {
  const response = await apiClient.delete(
    '/party/removeEmployee',
    employeeData,
  );
  return response.data;
};

/**
 * Atualiza as informações do funcionário em uma festa.
 * @param {Object} employeeData - Os dados do funcionário e da festa.
 * @returns {Promise<Object>} A resposta da atualização.
 */
export const updateEmployeeInfo = async (employeeData) => {
  const response = await apiClient.put(
    '/party/updateEmployeeInfo',
    employeeData,
  );
  return response.data;
};

export const createCategory = async (categoryData) => {
  const response = await apiClient.post('/party/createCategory', categoryData);
  return response.data;
};

export const searchCategory = async () => {
  const response = await apiClient.get('/party/searchCategory');
  return response.data;
};

export const editCategory = async (id, categoryData) => {
  const response = await apiClient.put(
    `/party/editCategory/${id}`,
    categoryData,
  );
  return response.data;
};

export const addCardToWallet = async (
  userIdOrCpf,
  cardNumber,
  expirationDate,
) => {
  const response = await apiClient.post('/wallet/addCard', {
    userIdOrCpf,
    cardNumber,
    expirationDate,
  });
  return response.data;
};

export const rechargeWallet = async (userIdOrCpf, amount) => {
  const response = await apiClient.post('/wallet/recharge', {
    userIdOrCpf,
    amount,
  });
  return response.data;
};

export const withdrawFromWallet = async (userIdOrCpf, cardNumber, amount) => {
  const response = await apiClient.post('/wallet/withdraw', {
    userIdOrCpf,
    cardNumber,
    amount,
  });
  return response.data;
};

export const getWallet = async (userIdOrCpf) => {
  if (!userIdOrCpf) {
    return;
  }

  const response = await apiClient.get(`/wallet/${userIdOrCpf}`);
  return response.data;
};

export const generateCardQRCode = async (userIdOrCpf) => {
  if (!userIdOrCpf) {
    return;
  }

  const response = await apiClient.get(`/wallet/generateQrCode/${userIdOrCpf}`);
  return response.data;
};

export const transferBalance = async (
  fromUserIdOrCpf,
  toUserIdOrCpf,
  amount,
) => {
  const response = await apiClient.post('/wallet/transfer', {
    fromUserIdOrCpf,
    toUserIdOrCpf,
    amount,
  });

  return response.data;
};
