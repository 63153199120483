import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import {
  Paper,
  Grid,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  fetchEmployeesLogged,
  fetchParties,
  fetchPartyDetails,
  fetchTotalSoldItems,
  fetchMostSold,
  fetchLowestStock,
} from '../../utils/api';
import { PermissionsProvider } from '../../contexts/Permissions/PermissionsContext';
import { GlobalTitle } from '../../utils/global';
import EmployeeDashboard from './EmployeeDashboard/EmployeeDashboard';
import LiveDashboard from './LiveDashboard/LiveDashboard';

const Home = () => {
  const [userGroup, setUserGroup] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parties, setParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState('');
  const [partyDetails, setPartyDetails] = useState([]);
  const [refreshInterval] = useState(60);
  const [countdown, setCountdown] = useState(refreshInterval);
  const [openLiveDashModal, setOpenLiveDashModal] = useState(false);

  useEffect(() => {
    handleDecodeToken();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(updatePartyDetails, refreshInterval * 1000);
    return () => clearInterval(intervalId);
  }, [partyDetails, refreshInterval, loading]);

  useEffect(() => {
    const countdownInterval = setInterval(updateCountdown, 1000);
    return () => clearInterval(countdownInterval);
  }, [refreshInterval]);

  const handleDecodeToken = async () => {
    const token = await localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserGroup(decodedToken.group);
        fetchEmployeeData();
        fetchPartiesData(decodedToken.org);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  };

  const fetchEmployeeData = async () => {
    setLoading(true);
    try {
      const employeeData = await fetchEmployeesLogged();
      if (employeeData) {
        setEmployee(employeeData);
        fetchPartiesDetails(employeeData.partysJob);
      }
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar dados do funcionário:', error);
      setLoading(false);
    }
  };

  const fetchPartiesData = async (org) => {
    try {
      const partiesData = await fetchParties();
      setParties(partiesData.filter((party) => party.org === org));
    } catch (error) {
      console.error('Erro ao buscar festas:', error);
    }
  };

  const hasPartyEnded = (endDate, endHour) => {
    const [endYear, endMonth, endDay] = endDate.split('-');
    const [endHourHour, endHourMinute] = endHour.split(':');
    const partyEndDateTime = new Date(
      endYear,
      endMonth - 1,
      endDay,
      endHourHour,
      endHourMinute,
    );
    return new Date() > partyEndDateTime;
  };

  const fetchPartyDetailsData = async (
    partyId,
    update = false,
    initialLoad = false,
  ) => {
    try {
      setLoading(true);
      const details = await fetchPartyDetails(partyId);

      if (hasPartyEnded(details.endDate, details.endHour)) {
        setPartyDetails((prevDetails) =>
          prevDetails.map((detail) =>
            detail.partyId === partyId
              ? { ...detail, partyEnded: true }
              : detail,
          ),
        );
      }

      if (update) {
        setPartyDetails((prevDetails) =>
          prevDetails.map((detail) =>
            detail.partyId === partyId ? { ...detail, ...details } : detail,
          ),
        );
      } else if (initialLoad) {
        setPartyDetails((prevDetails) => {
          if (prevDetails.some((detail) => detail.partyId === partyId)) {
            return prevDetails;
          }
          return [...prevDetails, { ...details, partyId }];
        });
      } else {
        setPartyDetails((prevDetails) => [
          ...prevDetails,
          { ...details, partyId },
        ]);
        const savedParties =
          JSON.parse(localStorage.getItem('selectedParties')) || [];
        localStorage.setItem(
          'selectedParties',
          JSON.stringify([...savedParties, partyId]),
        );
      }

      const solds = await fetchTotalSoldItems(partyId);
      const totalItems = solds.reduce(
        (acc, sold) => acc + sold.quantitySold,
        0,
      );
      const totalMoney = solds.reduce((acc, sold) => acc + sold.value, 0);

      setPartyDetails((prevDetails) =>
        prevDetails.map((detail) =>
          detail.partyId === partyId
            ? { ...detail, totalSoldItems: totalItems, totalMoney: totalMoney }
            : detail,
        ),
      );

      const mostSold = await fetchMostSold(partyId);
      setPartyDetails((prevDetails) =>
        prevDetails.map((detail) =>
          detail.partyId === partyId
            ? { ...detail, mostSold: mostSold }
            : detail,
        ),
      );

      const lowestStock = await fetchLowestStock(partyId);
      setPartyDetails((prevDetails) =>
        prevDetails.map((detail) =>
          detail.partyId === partyId
            ? { ...detail, lowestStock: lowestStock }
            : detail,
        ),
      );

      setLoading(false);
    } catch (error) {
      console.error('Erro ao obter detalhes da festa:', error);
      setLoading(false);
    }
  };

  const fetchPartiesDetails = async (partysJob) => {
    for (const job of partysJob) {
      await fetchPartyDetailsData(job.partyId, false, true);
    }
  };

  const handleAddPartyCard = () => {
    if (
      selectedParty &&
      !partyDetails.some((detail) => detail.partyId === selectedParty)
    ) {
      fetchPartyDetailsData(selectedParty);
    }
  };

  const handleRemovePartyCard = (partyId) => {
    setPartyDetails((prevDetails) =>
      prevDetails.filter((detail) => detail.partyId !== partyId),
    );
    const savedParties =
      JSON.parse(localStorage.getItem('selectedParties')) || [];
    localStorage.setItem(
      'selectedParties',
      JSON.stringify(savedParties.filter((id) => id !== partyId)),
    );
  };

  const handleRemoveAllPartyCards = () => {
    setPartyDetails([]);
    localStorage.setItem('selectedParties', JSON.stringify([]));
  };

  const updatePartyDetails = () => {
    if (!loading && partyDetails.length > 0) {
      partyDetails.forEach((party) => {
        fetchPartyDetailsData(party.partyId, true);
      });
      setCountdown(refreshInterval);
    }
  };

  const updateCountdown = () => {
    setCountdown((prevCountdown) =>
      prevCountdown > 0 ? prevCountdown - 1 : refreshInterval,
    );
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSelectedParty(value);
  };

  return (
    <PermissionsProvider group={userGroup}>
      <Paper elevation={3} sx={{ padding: '15px', borderRadius: '10px' }}>
        <GlobalTitle title="Página Inicial" />

        <Grid container component="main" spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => setOpenLiveDashModal(true)}
            >
              Abrir Dashboard de Festas ao Vivo
            </Button>
          </Grid>
          {loading ? (
            <CircularProgress />
          ) : (
            employee &&
            employee.partysJob &&
            employee.partysJob.length > 0 && (
              <EmployeeDashboard employee={employee} />
            )
          )}
        </Grid>
      </Paper>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openLiveDashModal}
        onClose={() => setOpenLiveDashModal(false)}
      >
        <DialogTitle>Dashboard de Festas ao Vivo</DialogTitle>
        <DialogContent>
          <LiveDashboard
            parties={parties}
            selectedParty={selectedParty}
            handleInputChange={handleInputChange}
            handleAddPartyCard={handleAddPartyCard}
            handleRemovePartyCard={handleRemovePartyCard}
            handleRemoveAllPartyCards={handleRemoveAllPartyCards}
            partyDetails={partyDetails}
            loading={loading}
            countdown={countdown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLiveDashModal(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </PermissionsProvider>
  );
};

export default Home;
