import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import { usePermissions } from '../../../contexts/Permissions/PermissionsContext';

const UserTable = ({ users, onEdit, onDelete, onResetPassword }) => {
  const { permissions } = usePermissions();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nome</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Ações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, index) => (
          <TableRow key={index}>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
              {permissions?.userGroups?.edit && (
                <IconButton onClick={() => onEdit(user)}>
                  <EditIcon />
                </IconButton>
              )}

              {permissions?.userGroups?.delete && (
                <IconButton onClick={() => onDelete(user._id)}>
                  <DeleteIcon />
                </IconButton>
              )}
              {permissions?.userGroups?.edit && (
                <IconButton onClick={() => onResetPassword(user._id)}>
                  <LockResetIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default UserTable;
