// Charts.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as XLSX from 'xlsx';
import { Button, Box, Grid } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { fetchSolds } from '../../../utils/api';

Chart.register(ChartDataLabels);

const Charts = () => {
  const { partyId } = useParams();
  const chartRef = useRef(null);
  const [chartType, setChartType] = useState('bar');

  const fetchAndRenderSolds = async () => {
    try {
      const soldsData = await fetchSolds(partyId);
      renderChartSolds(soldsData);
    } catch (error) {
      console.error('Erro ao obter vendas:', error);
    }
  };

  const renderChartSolds = (soldsData) => {
    const salesByProduct = {};

    soldsData.forEach((item) => {
      const productName = item.itemSold;
      const quantitySold = item.quantitySold;

      if (salesByProduct[productName]) {
        salesByProduct[productName] += quantitySold;
      } else {
        salesByProduct[productName] = quantitySold;
      }
    });

    const labels = Object.keys(salesByProduct);
    const data = Object.values(salesByProduct);
    const backgroundColors = labels.map(
      () => `hsl(${Math.random() * 360}, 100%, 75%)`,
    );

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById('salesChartSolds').getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: chartType,
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Quantidade Vendida',
            data: data,
            backgroundColor:
              chartType === 'pie'
                ? backgroundColors
                : 'rgba(54, 162, 235, 0.2)',
            borderColor:
              chartType === 'pie'
                ? 'rgba(255, 255, 255, 1)'
                : 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          delay: (context) => {
            let delay = 0;
            if (context.type === 'data' && context.mode === 'default') {
              delay = context.dataIndex * 300 + context.datasetIndex * 100;
            }
            return delay;
          },
        },
        scales:
          chartType === 'bar'
            ? {
                y: {
                  beginAtZero: true,
                },
              }
            : {},
        plugins: {
          datalabels: {
            color: 'black',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            borderRadius: 3,
            borderWidth: 1,
            borderColor: 'black',
            padding: 6,
            font: {
              weight: 'bold',
            },
            formatter: (value, context) => {
              const total = context.chart.data.datasets[0].data.reduce(
                (acc, val) => acc + val,
                0,
              );
              const percentage = ((value / total) * 100).toFixed(2) + '%';
              return percentage;
            },
          },
        },
      },
    });
  };

  const exportToExcel = () => {
    const salesByProduct = chartRef.current.data.labels.map((label, index) => ({
      Produto: label,
      Quantidade: chartRef.current.data.datasets[0].data[index],
    }));

    const worksheet = XLSX.utils.json_to_sheet(salesByProduct);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Vendas');

    XLSX.writeFile(workbook, 'vendas.xlsx');
  };

  const downloadChartImage = () => {
    const link = document.createElement('a');
    link.href = chartRef.current.toBase64Image();
    link.download = 'chart.png';
    link.click();
  };

  useEffect(() => {
    fetchAndRenderSolds();
  }, [partyId, chartType]);

  const toggleChartType = () => {
    setChartType((prevType) => (prevType === 'bar' ? 'pie' : 'bar'));
  };

  return (
    <>
      <CssBaseline />
      <Box textAlign="center" my={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={exportToExcel}
          style={{ margin: '10px' }}
        >
          Exportar para Excel
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={toggleChartType}
          style={{ margin: '10px' }}
        >
          Mudar para {chartType === 'bar' ? 'Pizza' : 'Barra'}
        </Button>
        <Button
          variant="outlined"
          onClick={downloadChartImage}
          style={{ margin: '10px' }}
        >
          Baixar Imagem do Gráfico
        </Button>
      </Box>

      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={10} lg={8}>
          <Box position="relative" height="50vh">
            <canvas
              id="salesChartSolds"
              style={{ width: '100%', height: '100%' }}
            ></canvas>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Charts;
