import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import ListStock from '../ListStock/ListStock';

const StockModal = ({ open, onClose }) => (
  <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
    <DialogTitle>Estoque Da Festa</DialogTitle>
    <DialogContent>
      <ListStock />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Fechar</Button>
    </DialogActions>
  </Dialog>
);

export default StockModal;
