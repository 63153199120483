import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { fetchParties, editParty } from '../../utils/api';
import CreatePartyModal from './CreatePartyModal/CreatePartyModal';
import EditPartyModal from './EditPartyModal/EditPartyModal';
import { usePermissions } from '../../contexts/Permissions/PermissionsContext';
import AddIcon from '@mui/icons-material/Add';
import { GlobalTitle } from '../../utils/global';

const Partys = () => {
  const [partys, setPartys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedParty, setSelectedParty] = useState(null);
  const [filter, setFilter] = useState('all');
  const [errorMessage, setErrorMessage] = useState('');
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  const handleGetPartys = async () => {
    setIsLoading(true);
    try {
      const partysData = await fetchParties();
      const sortedPartys = partysData.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate),
      );
      setPartys(sortedPartys);
    } catch (error) {
      console.error('Erro ao obter festas:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetPartys();
  }, [isModalOpen, isEditModalOpen]);

  const handlePartyClick = (partyId) => {
    navigate(`/party/${partyId}`);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenEditModal = (party) => {
    setSelectedParty(party);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedParty(null);
    setErrorMessage('');
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSaveEdit = async (updatedParty) => {
    try {
      const response = await editParty(updatedParty);

      if (response) {
        // Atualize a lista de festas
        handleGetPartys();
        handleCloseEditModal();
      } else {
        console.error('Erro ao salvar as mudanças');
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        console.error('Erro ao salvar as mudanças', error);
      }
    }
  };

  const filteredPartys = partys.filter((party) => {
    const now = new Date();
    if (filter === 'finalized') {
      return new Date(party.endDate) < now;
    } else if (filter === 'ongoing') {
      return new Date(party.endDate) >= now;
    }
    return true;
  });

  return (
    <>
      <Paper elevation={3} sx={{ padding: '15px', borderRadius: '10px' }}>
        <GlobalTitle title="Gerenciamento de Festas" />
        {permissions.parties?.edit && (
          <Box display="flex" justifyContent="space-between" mb={2}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Filtro</InputLabel>
              <Select
                value={filter}
                onChange={handleFilterChange}
                label="Filtro"
              >
                <MenuItem value="all">Todas</MenuItem>
                <MenuItem value="finalized">Finalizadas</MenuItem>
                <MenuItem value="ongoing">Em Andamento</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Criar Festa
            </Button>
            <CreatePartyModal isOpen={isModalOpen} onClose={handleCloseModal} />
          </Box>
        )}
        <div>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : Array.isArray(filteredPartys) && filteredPartys.length > 0 ? (
            filteredPartys.map((party) => (
              <Card key={party.partyId} sx={{ my: 3 }}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {party.nameParty}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Party ID: {party.partyId}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Data Inicio: {party.startDate}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Data Fim: {party.endDate}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Local: {party.local}
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    <Button onClick={() => handlePartyClick(party.partyId)}>
                      Ver detalhes da festa
                    </Button>
                    {permissions.parties?.edit && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenEditModal(party)}
                      >
                        Editar
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="h6" component="p">
              Nenhuma Festa Encontrada
            </Typography>
          )}
        </div>
      </Paper>
      {isEditModalOpen && selectedParty && (
        <EditPartyModal
          party={selectedParty}
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          onSave={handleSaveEdit}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};

export default Partys;
