import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Snackbar,
} from '@mui/material';

const StockModal = ({
  open,
  handleClose,
  handleInputChange,
  currentItem,
  addItem,
  handleItemListModalOpen,
}) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);

  const handleCloseSnackbar = () => {
    setFileUploaded(false);
    setItemAdded(false);
  };

  const handleAddItem = () => {
    addItem();
    setItemAdded(true);
  };

  const handleFinalize = () => {
    handleItemListModalOpen();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%' },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 0,
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ p: 4, overflowY: 'auto', flex: 1 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Adicionar Estoque
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nome do Produto"
                name="nameProduct"
                variant="outlined"
                fullWidth
                value={currentItem.nameProduct}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Categoria"
                name="category"
                variant="outlined"
                fullWidth
                value={currentItem.category}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Quantidade"
                name="qtd"
                variant="outlined"
                type="number"
                fullWidth
                placeholder="Ex: 10"
                value={currentItem.qtd}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="qtd-unit-label">Unidade Qtd</InputLabel>
                <Select
                  labelId="qtd-unit-label"
                  name="qtdUnit"
                  value={currentItem.qtdUnit}
                  onChange={handleInputChange}
                  label="Unidade Qtd"
                >
                  <MenuItem value="Latas">Latas</MenuItem>
                  <MenuItem value="Unidades">Unidades</MenuItem>
                  <MenuItem value="Unidades">Pacotes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Volume"
                name="volume"
                variant="outlined"
                fullWidth
                placeholder="Ex: 200"
                value={currentItem.volume}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="volume-unit-label">Unidade Vol.</InputLabel>
                <Select
                  labelId="volume-unit-label"
                  name="volumeUnit"
                  value={currentItem.volumeUnit}
                  onChange={handleInputChange}
                  label="Unidade Vol."
                >
                  <MenuItem value="ml">Mililitros</MenuItem>
                  <MenuItem value="Litros">Litros</MenuItem>
                  <MenuItem value="g">Gramas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Preço de Compra"
                name="purchasePrice"
                variant="outlined"
                type="number"
                fullWidth
                value={currentItem.purchasePrice}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Preço de Venda"
                name="sellingPrice"
                variant="outlined"
                type="number"
                fullWidth
                value={currentItem.sellingPrice}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observações"
                name="observations"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={currentItem.observations}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            borderTop: '1px solid #000',
            p: 2,
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Button variant="outlined" onClick={handleAddItem} fullWidth>
                Adicionar Item
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleFinalize}
                fullWidth
              >
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Snackbar
          open={fileUploaded}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message="Arquivo importado com sucesso! Clique em 'Visualizar Itens' para verificar."
        />
        <Snackbar
          open={itemAdded}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message="Item adicionado com sucesso!"
        />
      </Box>
    </Modal>
  );
};

export default StockModal;
