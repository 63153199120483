import React, { useEffect, useState, useCallback, useReducer } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import {
  fetchPartyDetails,
  fetchSolds,
  sendSaleItems,
} from '../../../utils/api';

// Initial state for reducer
const initialState = {
  soldsByItem: {},
  availableItems: [],
  newSaleItems: [],
  currentItem: { itemSold: '', qtdSold: '', value: '' },
  snackbar: { open: false, message: '', severity: 'error' },
};

// Reducer function
function reducer(state, action) {
  switch (action.type) {
    case 'SET_SOLD_ITEMS':
      return { ...state, soldsByItem: action.payload };
    case 'SET_AVAILABLE_ITEMS':
      return { ...state, availableItems: action.payload };
    case 'ADD_NEW_SALE_ITEM':
      return {
        ...state,
        newSaleItems: [...state.newSaleItems, action.payload],
      };
    case 'REMOVE_SALE_ITEM':
      return {
        ...state,
        newSaleItems: state.newSaleItems.filter(
          (_, index) => index !== action.payload,
        ),
      };
    case 'UPDATE_CURRENT_ITEM':
      return {
        ...state,
        currentItem: { ...state.currentItem, ...action.payload },
      };
    case 'RESET_CURRENT_ITEM':
      return { ...state, currentItem: initialState.currentItem };
    case 'SET_SNACKBAR':
      return { ...state, snackbar: { open: true, ...action.payload } };
    case 'CLOSE_SNACKBAR':
      return { ...state, snackbar: initialState.snackbar };
    default:
      return state;
  }
}

const ListSolds = () => {
  const { partyId } = useParams();
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { soldsByItem, availableItems, newSaleItems, currentItem, snackbar } =
    state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchPartyDetails(partyId);
        dispatch({ type: 'SET_AVAILABLE_ITEMS', payload: products });

        const solds = await fetchSolds(partyId);
        const soldsGroupedByItem = groupSoldsByItem(solds);
        dispatch({ type: 'SET_SOLD_ITEMS', payload: soldsGroupedByItem });
      } catch (error) {
        console.error('Erro ao buscar dados da festa:', error);
      }
    };

    fetchData();
  }, [partyId]);

  const groupSoldsByItem = (soldsData) => {
    const groupedSolds = {};
    if (Array.isArray(soldsData)) {
      soldsData.forEach((item) => {
        if (!groupedSolds[item.itemSold]) {
          groupedSolds[item.itemSold] = {
            value: 0,
            quantity: 0,
            total: 0,
          };
        }
        groupedSolds[item.itemSold].value = item.value;
        groupedSolds[item.itemSold].quantity += item.quantitySold;
        groupedSolds[item.itemSold].total += item.value * item.quantitySold;
      });
    }
    return groupedSolds;
  };

  const calculateTotalSum = useCallback(() => {
    return Object.values(soldsByItem).reduce(
      (sum, { total }) => sum + total,
      0,
    );
  }, [soldsByItem]);

  const exportToExcel = useCallback(() => {
    const data = Object.entries(soldsByItem).map(
      ([itemName, { value, quantity, total }]) => ({
        Item: itemName,
        'Valor Unidade': value,
        'Quantidade Vendida': quantity,
        'Valor Total': total,
      }),
    );

    const totalSum = calculateTotalSum();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(worksheet, [['', '', 'Total', totalSum]], {
      origin: -1,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Vendas');
    XLSX.writeFile(workbook, `Export-Vendas-${partyId}.xlsx`);
  }, [soldsByItem, calculateTotalSum, partyId]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'UPDATE_CURRENT_ITEM', payload: { [name]: value } });
  };

  const addItem = () => {
    if (!currentItem.itemSold || !currentItem.qtdSold || !currentItem.value) {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message:
            'Por favor, preencha todos os campos antes de adicionar o item.',
          severity: 'error',
        },
      });
      return;
    }

    if (currentItem.qtdSold <= 0) {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'Erro: A quantidade vendida deve ser maior que 0.',
          severity: 'error',
        },
      });
      return;
    }

    const itemAvailable = availableItems.find(
      (item) => item.nameProduct === currentItem.itemSold,
    );
    if (!itemAvailable) {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Erro: Item não encontrado.', severity: 'error' },
      });
      return;
    }

    if (currentItem.qtdSold > itemAvailable.quantity) {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Erro: Você está tentando vender mais do que tem disponível para o item ${currentItem.itemSold}. Quantidade disponível: ${itemAvailable.quantity}`,
          severity: 'error',
        },
      });
      return;
    }

    dispatch({
      type: 'ADD_NEW_SALE_ITEM',
      payload: {
        ...currentItem,
        qtdSold: Number(currentItem.qtdSold),
        value: Number(currentItem.value),
      },
    });
    dispatch({ type: 'RESET_CURRENT_ITEM' });
  };

  const removeItem = (index) => {
    dispatch({ type: 'REMOVE_SALE_ITEM', payload: index });
  };

  const sendSaleData = async () => {
    try {
      const response = await sendSaleItems(
        newSaleItems.map((item) => ({ ...item, partyId })),
      );
      if (response !== 200) throw new Error('Erro ao enviar dados de venda');

      const solds = await fetchSolds(partyId);
      const soldsGroupedByItem = groupSoldsByItem(solds);
      dispatch({ type: 'SET_SOLD_ITEMS', payload: soldsGroupedByItem });
      dispatch({ type: 'RESET_CURRENT_ITEM' });
      handleClose();
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  const handleCloseSnackbar = () => {
    dispatch({ type: 'CLOSE_SNACKBAR' });
  };

  return (
    <>
      <Button
        sx={{ mb: 2 }}
        variant="outlined"
        color="primary"
        onClick={exportToExcel}
      >
        Exportar para Excel
      </Button>
      <Button
        sx={{ mb: 2, ml: 2 }}
        variant="outlined"
        color="secondary"
        onClick={handleOpen}
      >
        Adicionar Venda
      </Button>
      <Grid container spacing={2}>
        {Object.entries(soldsByItem).map(
          ([itemName, { value, quantity, total }], index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card sx={{ marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Item: {itemName}
                  </Typography>
                  <Typography variant="body1">
                    Valor Unidade: {value}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Quantidade Vendida: {quantity}
                  </Typography>
                  <Typography variant="body2">Valor Total: {total}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ),
        )}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: 600 },
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Carrinho
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="select-party-label">
                Item a ser vendido
              </InputLabel>
              <Select
                name="itemSold"
                value={currentItem.itemSold}
                onChange={handleInputChange}
                fullWidth
                sx={{ mt: 2 }}
              >
                {availableItems.map((item, index) => (
                  <MenuItem key={index} value={item.nameProduct}>
                    {item.nameProduct}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Quantidade Vendida"
                name="qtdSold"
                type="number"
                value={currentItem.qtdSold}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Valor"
                name="value"
                type="number"
                value={currentItem.value}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            {newSaleItems.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" component="h2">
                  Itens Adicionados:
                </Typography>
                <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                  <Grid container spacing={2}>
                    {newSaleItems.map((item, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Card sx={{ mt: 1, width: '100%' }}>
                          <CardContent>
                            <Typography variant="body1">
                              Item: {item.itemSold}
                            </Typography>
                            <Typography variant="body1">
                              Quantidade: {item.qtdSold}
                            </Typography>
                            <Typography variant="body1">
                              Valor: {item.value}
                            </Typography>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => removeItem(index)}
                            >
                              Remover
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
          <Button sx={{ mt: 2 }} variant="outlined" onClick={addItem}>
            Adicionar Item
          </Button>
          {newSaleItems.length > 0 && (
            <Button
              sx={{ mt: 2, ml: 1 }}
              variant="outlined"
              color="secondary"
              onClick={sendSaleData}
            >
              Finalizar Venda
            </Button>
          )}
          <Snackbar
            open={snackbar.open}
            autoHideDuration={1500}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              sx={{ width: '100%' }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Modal>
    </>
  );
};

export default ListSolds;
