import { jwtDecode } from 'jwt-decode';

/**
 * Valida se o token esta valido
 * @param {string} token - Token que esta no local.
 * @returns {Promise<Boolean>} true e false.
 */
export const isTokenValid = (token) => {
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 > Date.now();
  } catch {
    return false;
  }
};
