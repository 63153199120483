import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const paginas = [
  { name: 'home', label: 'Início' },
  { name: 'parties', label: 'Festas' },
  { name: 'products', label: 'Produtos' },
  { name: 'solds', label: 'Vendas' },
  { name: 'stocks', label: 'Estoque' },
  { name: 'employees', label: 'Funcionários' },
  { name: 'userGroups', label: 'Grupos de Usuários' },
  { name: 'cards', label: 'Cards' },
  { name: 'categories', label: 'Categorias' },
];

const GroupFormDialog = ({
  open,
  onClose,
  onSubmit,
  newGroup,
  handleInputChange,
  handlePermissionsChange,
  editMode,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>{editMode ? 'Editar Grupo' : 'Novo Grupo'}</DialogTitle>
    <DialogContent>
      <form onSubmit={onSubmit}>
        <Box sx={{ mb: 2 }}>
          <TextField
            margin="dense"
            name="name"
            label="Nome do Grupo"
            type="text"
            fullWidth
            value={newGroup.name}
            onChange={handleInputChange}
            required
          />
        </Box>
        {paginas.map((page) => (
          <Box key={page.name} sx={{ mb: 2 }}>
            <h4>{page.label}</h4>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newGroup.permissions[page.name]?.view || false}
                  onChange={(e) =>
                    handlePermissionsChange(page.name, 'view', e.target.checked)
                  }
                  name={`${page.name}_view`}
                />
              }
              label="Visualizar"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newGroup.permissions[page.name]?.edit || false}
                  onChange={(e) =>
                    handlePermissionsChange(page.name, 'edit', e.target.checked)
                  }
                  name={`${page.name}_edit`}
                />
              }
              label="Editar"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newGroup.permissions[page.name]?.delete || false}
                  onChange={(e) =>
                    handlePermissionsChange(
                      page.name,
                      'delete',
                      e.target.checked,
                    )
                  }
                  name={`${page.name}_delete`}
                />
              }
              label="Deletar"
            />
          </Box>
        ))}
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button type="submit" variant="outlined" color="primary">
            {editMode ? 'Salvar' : 'Cadastrar'}
          </Button>
        </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
);

export default GroupFormDialog;
