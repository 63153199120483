import React, { useState, useEffect } from 'react';
import {
  Button,
  Paper,
  Box,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  fetchEmployees,
  registerUser,
  fetchGroups,
  deleteUser,
  resetPassword,
  editUser,
  createGroup,
  editGroup,
  deleteGroup,
} from '../../utils/api';
import UserTable from './UserTable/UserTable';
import UserFormDialog from './UserFormDialog/UserFormDialog';
import GroupFormDialog from './GroupFormDialog/GroupFormDialog';
import GroupTable from './GroupTable/GroupTable';
import AlertSnackbar from './AlertSnackbar/AlertSnackbar';
import UserInfoModal from './UserInfoModal/UserInfoModal';
import { jwtDecode } from 'jwt-decode';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { GlobalTitle } from '../../utils/global';
import { usePermissions } from '../../contexts/Permissions/PermissionsContext';

const paginas = [
  { name: 'home', label: 'Início' },
  { name: 'parties', label: 'Festas' },
  { name: 'products', label: 'Produtos' },
  { name: 'solds', label: 'Vendas' },
  { name: 'stocks', label: 'Estoque' },
  { name: 'employees', label: 'Funcionários' },
  { name: 'userGroups', label: 'Grupos de Usuários' },
];

const UserManagement = () => {
  const [open, setOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [userFilter, setUserFilter] = useState('');
  const [groupFilter, setGroupFilter] = useState('');
  const [newUser, setNewUser] = useState({
    username: '',
    name: '',
    group: '',
    cel: '',
    email: '',
  });
  const [newGroup, setNewGroup] = useState({
    name: '',
    permissions: paginas.reduce((acc, page) => {
      acc[page.name] = { view: true, edit: false, delete: false };
      return acc;
    }, {}),
  });
  const [editMode, setEditMode] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    severity: '',
    message: '',
  });
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [deleteType, setDeleteType] = useState('');
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [resetPasswordTarget, setResetPasswordTarget] = useState(null);

  const { permissions } = usePermissions();

  useEffect(() => {
    fetchUsers();
    fetchGroupsData();
    fetchLoggedInUserInfo();
  }, []);

  const fetchUsers = async () => {
    setLoadingUsers(true);
    try {
      const data = await fetchEmployees();
      setUsers(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const fetchGroupsData = async () => {
    setLoadingGroups(true);
    try {
      const data = await fetchGroups();
      setGroups(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching groups:', error);
    } finally {
      setLoadingGroups(false);
    }
  };

  const fetchLoggedInUserInfo = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      setLoggedInUser(decoded);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleGroupInputChange = (e) => {
    const { name, value } = e.target;
    setNewGroup({ ...newGroup, [name]: value });
  };

  const handlePermissionsChange = (page, permission, value) => {
    setNewGroup((prevState) => ({
      ...prevState,
      permissions: {
        ...prevState.permissions,
        [page]: {
          ...prevState.permissions[page],
          [permission]: value,
        },
      },
    }));
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = { ...newUser };
      if (editMode) {
        await editUser(currentUserId, userData);
      } else {
        await registerUser(userData);
      }
      fetchUsers();
      setOpen(false);
      setNewUser({
        username: '',
        name: '',
        group: '',
        cel: '',
        email: '',
      });
      setAlert({
        open: true,
        severity: 'success',
        message: editMode
          ? 'Usuário atualizado com sucesso!'
          : 'Usuário adicionado com sucesso!',
      });
      setEditMode(false);
      setCurrentUserId(null);
    } catch (error) {
      console.error(
        editMode ? 'Erro ao atualizar usuário:' : 'Erro ao adicionar usuário:',
        error,
      );
      setAlert({
        open: true,
        severity: 'error',
        message: editMode
          ? 'Falha ao atualizar usuário'
          : 'Falha ao adicionar usuário',
      });
    }
  };

  const handleGroupSubmit = async (e) => {
    e.preventDefault();
    try {
      const groupData = { ...newGroup };
      if (editMode) {
        await editGroup(currentGroupId, groupData);
      } else {
        await createGroup(groupData);
      }
      fetchGroupsData();
      setGroupOpen(false);
      setNewGroup({
        name: '',
        permissions: paginas.reduce((acc, page) => {
          acc[page.name] = { view: true, edit: false, delete: false };
          return acc;
        }, {}),
      });
      setAlert({
        open: true,
        severity: 'success',
        message: editMode
          ? 'Grupo atualizado com sucesso!'
          : 'Grupo adicionado com sucesso!',
      });
      setEditMode(false);
      setCurrentGroupId(null);
    } catch (error) {
      console.error(
        editMode ? 'Erro ao atualizar grupo:' : 'Erro ao adicionar grupo:',
        error,
      );
      setAlert({
        open: true,
        severity: 'error',
        message: editMode
          ? 'Falha ao atualizar grupo'
          : 'Falha ao adicionar grupo',
      });
    }
  };

  const handleEditUser = (user) => {
    const { _id, username, name, group, cel, email } = user;
    setNewUser({ username, name, group, cel, email });
    setCurrentUserId(_id);
    setEditMode(true);
    setOpen(true);
  };

  const handleEditGroup = (group) => {
    const { _id, name, permissions } = group;
    const formattedPermissions = paginas.reduce((acc, page) => {
      acc[page.name] = permissions[page.name] || {
        view: false,
        edit: false,
        delete: false,
      };
      return acc;
    }, {});
    setNewGroup({ name, permissions: formattedPermissions });
    setCurrentGroupId(_id);
    setEditMode(true);
    setGroupOpen(true);
  };

  const handleDelete = (type, id) => {
    setDeleteType(type);
    setDeleteTarget(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (deleteType === 'user') {
        await deleteUser(deleteTarget);
        fetchUsers();
        setAlert({
          open: true,
          severity: 'success',
          message: 'Usuário deletado com sucesso!',
        });
      } else if (deleteType === 'group') {
        await deleteGroup(deleteTarget);
        fetchGroupsData();
        setAlert({
          open: true,
          severity: 'success',
          message: 'Grupo deletado com sucesso!',
        });
      }
    } catch (error) {
      console.error('Erro ao deletar:', error);
      setAlert({ open: true, severity: 'error', message: 'Falha ao deletar' });
    } finally {
      setDeleteDialogOpen(false);
      setDeleteTarget(null);
      setDeleteType('');
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteTarget(null);
    setDeleteType('');
  };

  const handleResetPassword = (userId) => {
    setResetPasswordTarget(userId);
    setResetPasswordDialogOpen(true);
  };

  const handleResetPasswordConfirm = async () => {
    try {
      await resetPassword(resetPasswordTarget);
      setAlert({
        open: true,
        severity: 'success',
        message: 'Senha resetada com sucesso!',
      });
    } catch (error) {
      console.error('Erro ao resetar senha:', error);
      setAlert({
        open: true,
        severity: 'error',
        message: 'Falha ao resetar senha',
      });
    } finally {
      setResetPasswordDialogOpen(false);
      setResetPasswordTarget(null);
    }
  };

  const handleResetPasswordCancel = () => {
    setResetPasswordDialogOpen(false);
    setResetPasswordTarget(null);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(userFilter.toLowerCase()),
  );

  const filteredGroups = groups.filter((group) =>
    group.name.toLowerCase().includes(groupFilter.toLowerCase()),
  );

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      <Paper elevation={3} sx={{ padding: '15px', borderRadius: '10px' }}>
        <GlobalTitle title="Gerenciamento de Usuários" />
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="Funcionários" />
          <Tab label="Grupos" />
        </Tabs>
        {tabIndex === 0 && (
          <>
            <Box sx={{ mb: 2 }}>
              {permissions?.userGroups?.edit && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setOpen(true)}
                  sx={{ mr: 2 }}
                >
                  Novo Usuário
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AccountCircleIcon />}
                onClick={() => setUserInfoOpen(true)}
              >
                Minha Conta
              </Button>
            </Box>
            {loadingUsers ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TextField
                  label="Filtrar por Nome"
                  variant="outlined"
                  value={userFilter}
                  onChange={(e) => setUserFilter(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Paper sx={{ mb: 2, p: 2 }}>
                  <UserTable
                    users={filteredUsers}
                    onEdit={handleEditUser}
                    onDelete={(id) => handleDelete('user', id)}
                    onResetPassword={handleResetPassword}
                  />
                </Paper>
              </>
            )}
          </>
        )}
        {tabIndex === 1 && (
          <>
            <Box sx={{ mb: 2 }}>
              {permissions?.userGroups?.edit && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setGroupOpen(true)}
                  sx={{ mr: 2 }}
                >
                  Novo Grupo
                </Button>
              )}
            </Box>
            {loadingGroups ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TextField
                  label="Filtrar por Nome"
                  variant="outlined"
                  value={groupFilter}
                  onChange={(e) => setGroupFilter(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Paper sx={{ mb: 2, p: 2 }}>
                  <GroupTable
                    groups={filteredGroups}
                    onEdit={handleEditGroup}
                    onDelete={(id) => handleDelete('group', id)}
                  />
                </Paper>
              </>
            )}
          </>
        )}
        <UserFormDialog
          open={open}
          onClose={() => {
            setOpen(false);
            setEditMode(false);
            setCurrentUserId(null);
          }}
          onSubmit={handleUserSubmit}
          newUser={newUser}
          handleInputChange={handleInputChange}
          isCompany={isCompany}
          setIsCompany={setIsCompany}
          groups={groups}
          editMode={editMode}
        />
        <GroupFormDialog
          open={groupOpen}
          onClose={() => {
            setGroupOpen(false);
            setEditMode(false);
            setCurrentGroupId(null);
          }}
          onSubmit={handleGroupSubmit}
          newGroup={newGroup}
          handleInputChange={handleGroupInputChange}
          handlePermissionsChange={handlePermissionsChange}
          editMode={editMode}
        />
        <UserInfoModal
          open={userInfoOpen}
          onClose={() => setUserInfoOpen(false)}
          user={loggedInUser}
        />
        <AlertSnackbar alert={alert} setAlert={setAlert} />
      </Paper>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja deletar este{' '}
            {deleteType === 'user' ? 'usuário' : 'grupo'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={resetPasswordDialogOpen}
        onClose={handleResetPasswordCancel}
      >
        <DialogTitle>Confirmação de Resetar Senha</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja resetar a senha deste usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetPasswordCancel} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleResetPasswordConfirm} color="primary">
            Resetar Senha
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserManagement;
