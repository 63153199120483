import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { createParty } from '../../../utils/api';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CreatePartyModal = ({ isOpen, onClose }) => {
  const [partyData, setPartyData] = useState({
    nameParty: '',
    local: '',
    startDate: '',
    endDate: '',
    startHour: '',
    endHour: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPartyData({
      ...partyData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createParty(partyData);
      onClose(); // Close the modal after creating the party
    } catch (error) {
      console.error('Erro ao criar festa:', error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Criar Nova Festa
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            label="Nome da Festa"
            name="nameParty"
            value={partyData.nameParty}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Local"
            name="local"
            value={partyData.local}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Data de Início"
            type="date"
            name="startDate"
            value={partyData.startDate}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Data de Término"
            type="date"
            name="endDate"
            value={partyData.endDate}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Hora de Início"
            type="time"
            name="startHour"
            value={partyData.startHour}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Hora de Término"
            type="time"
            name="endHour"
            value={partyData.endHour}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ mt: 2, mr: 1 }}
          >
            Criar Festa
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CancelIcon />}
            onClick={onClose}
            sx={{ mt: 2 }}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreatePartyModal;
