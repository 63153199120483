import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

const EditPartyModal = ({ party, isOpen, onClose, onSave, errorMessage }) => {
  const [nameParty, setNameParty] = useState('');
  const [local, setLocal] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startHour, setStartHour] = useState('');
  const [endHour, setEndHour] = useState('');

  useEffect(() => {
    if (party) {
      setNameParty(party.nameParty);
      setLocal(party.local);
      setStartDate(party.startDate);
      setEndDate(party.endDate);
      setStartHour(party.startHour);
      setEndHour(party.endHour);
    }
  }, [party]);

  const handleSave = () => {
    const updatedParty = {
      ...party,
      nameParty,
      local,
      startDate,
      endDate,
      startHour,
      endHour,
    };
    onSave(updatedParty);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{ ...style, width: 400 }}>
        <Typography variant="h6" component="h2">
          Editar Festa
        </Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          label="Nome da Festa"
          value={nameParty}
          onChange={(e) => setNameParty(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Local"
          value={local}
          onChange={(e) => setLocal(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Data de Início"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Data de Término"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Hora de Início"
          value={startHour}
          onChange={(e) => setStartHour(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Hora de Término"
          value={endHour}
          onChange={(e) => setEndHour(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleSave}
          sx={{ mt: 2, mr: 1 }}
        >
          Salvar
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={onClose}
          sx={{ mt: 2 }}
        >
          Cancelar
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default EditPartyModal;
