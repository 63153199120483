import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Typography,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Select,
  MenuItem,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Checkbox,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import {
  fetchEmployees,
  addEmployees,
  removeEmployee,
  updateEmployeeInfo,
} from '../../../utils/api';

const EmployeeManagementModal = ({
  open,
  onClose,
  partyId,
  setSuccessMessage,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [editEmployeeId, setEditEmployeeId] = useState(null);
  const [editSalary, setEditSalary] = useState('');
  const [editExtra, setEditExtra] = useState('');

  useEffect(() => {
    fetchAllUsers();
    fetchAllEmployees();
  }, [partyId]);

  const fetchAllUsers = async () => {
    try {
      const usersData = await fetchEmployees();
      setUsers(usersData);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  const fetchAllEmployees = async () => {
    try {
      const employeesData = await fetchEmployees();
      const partyEmployees = employeesData.filter(
        (employee) =>
          employee.partysJob &&
          employee.partysJob.some((job) => job.partyId === partyId),
      );
      setEmployees(partyEmployees);
    } catch (error) {
      console.error('Erro ao buscar funcionários:', error);
    }
  };

  const handleAddEmployee = async () => {
    try {
      await addEmployees({ partyId, _id: selectedUser });
      setSelectedUser('');
      setErrorMessage('');
      setSuccessMessage('Funcionário adicionado com sucesso!');
      fetchAllEmployees();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || 'Erro ao adicionar funcionário',
      );
    }
  };

  const handleRemoveEmployee = async () => {
    try {
      await removeEmployee({ partyId, _id: selectedUser });
      setSelectedUser('');
      setErrorMessage('');
      setSuccessMessage('Funcionário removido com sucesso!');
      fetchAllEmployees();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || 'Erro ao remover funcionário',
      );
    }
  };

  const handleUpdateEmployeeInfo = async (userId, salary, extra, isPaid) => {
    try {
      await updateEmployeeInfo({ partyId, userId, salary, extra, isPaid });
      setSuccessMessage('Informações do funcionário atualizadas com sucesso!');
      fetchAllEmployees();
      setEditEmployeeId(null);
    } catch (error) {
      console.error('Erro ao atualizar informações do funcionário:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const startEditing = (employee) => {
    const job = employee.partysJob.find((job) => job.partyId === partyId);
    setEditEmployeeId(employee._id);
    setEditSalary(job ? job.salary : '');
    setEditExtra(job ? job.extra : '');
  };

  const cancelEditing = () => {
    setEditEmployeeId(null);
    setEditSalary('');
    setEditExtra('');
  };

  const saveEmployeeInfo = (employeeId) => {
    handleUpdateEmployeeInfo(employeeId, editSalary, editExtra, false);
  };

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Gerenciar Funcionários</DialogTitle>
      <DialogContent>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Adicionar Funcionário" />
          <Tab label="Remover Funcionário" />
          <Tab label="Funcionários da Festa" />
        </Tabs>
        <Box hidden={tabIndex !== 0} sx={{ paddingTop: 2 }}>
          <Typography variant="h6">Adicionar Funcionário</Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Select
            label="Funcionário"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            fullWidth
          >
            {users.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleAddEmployee}>Adicionar</Button>
        </Box>
        <Box hidden={tabIndex !== 1} sx={{ paddingTop: 2 }}>
          <Typography variant="h6">Remover Funcionário</Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Select
            label="Funcionário"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            fullWidth
          >
            {employees.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleRemoveEmployee}>Remover</Button>
        </Box>
        <Box hidden={tabIndex !== 2} sx={{ paddingTop: 2 }}>
          <Typography variant="h6">Funcionários da Festa</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Salário</TableCell>
                  <TableCell>Extra</TableCell>
                  <TableCell>Pago</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((employee) => {
                  const job = employee.partysJob.find(
                    (job) => job.partyId === partyId,
                  );
                  return (
                    <TableRow key={employee._id}>
                      <TableCell>{employee.name}</TableCell>
                      <TableCell>
                        {editEmployeeId === employee._id ? (
                          <TextField
                            value={editSalary}
                            onChange={(e) => setEditSalary(e.target.value)}
                            type="number"
                          />
                        ) : (
                          `R$ ${job ? job.salary : ''}`
                        )}
                      </TableCell>
                      <TableCell>
                        {editEmployeeId === employee._id ? (
                          <TextField
                            value={editExtra}
                            onChange={(e) => setEditExtra(e.target.value)}
                            type="number"
                          />
                        ) : (
                          `R$ ${job ? job.extra : ''}`
                        )}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={job ? job.isPaid : false}
                          onChange={() =>
                            handleUpdateEmployeeInfo(
                              employee._id,
                              job.salary,
                              job.extra,
                              !job.isPaid,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {editEmployeeId === employee._id ? (
                          <>
                            <Button
                              onClick={() => saveEmployeeInfo(employee._id)}
                            >
                              Salvar
                            </Button>
                            <Button onClick={cancelEditing}>Cancelar</Button>
                          </>
                        ) : (
                          <IconButton onClick={() => startEditing(employee)}>
                            <EditIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeManagementModal;
