import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography variant="subtitle1" gutterBottom>
        A página que você está procurando pode ter sido removida ou está
        temporariamente indisponível.
      </Typography>
      <img
        src={`https://http.cat/404`}
        alt={'image 404 not found cat hidden'}
        height={'400px'}
        loading="lazy"
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate('/login')}
        sx={{ mt: 3 }}
      >
        Voltar para o Login
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate('/')}
        sx={{ mt: 3 }}
      >
        Voltar para o Inicio
      </Button>
    </Box>
  );
};

export default NotFoundPage;
