import { styled } from '@mui/system';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import Logo from '../imgs/logoDark.png';

export const BlinkingDot = () => {
  const BlinkingDot = styled(FiberManualRecordIcon)(({ theme }) => ({
    color: theme.palette.error.main,
    animation: 'blinking 1s infinite',
    marginRight: theme.spacing(1),
    marginTop: 1,
    '@keyframes blinking': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
  }));

  return <BlinkingDot />;
};

export const LoadFull = () => {
  return (
    <Box
      className="mobile-scroll"
      sx={{ py: 1, overflowY: 'hidden', height: '100vh', display: 'block' }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={12}
            mt={12}
          >
            <img
              src={Logo}
              alt="logo da paynparty"
              style={{ height: '60px' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export const GlobalTitle = ({ title }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
    </Box>
  );
};
