import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const AlertSnackbar = ({ alert, setAlert }) => (
  <Snackbar
    open={alert.open}
    autoHideDuration={6000}
    onClose={() => setAlert({ ...alert, open: false })}
  >
    <Alert
      onClose={() => setAlert({ ...alert, open: false })}
      severity={alert.severity}
      sx={{ width: '100%' }}
    >
      {alert.message}
    </Alert>
  </Snackbar>
);

export default AlertSnackbar;
