import React from 'react';
import PartySelector from '../PartySelector/PartySelector';
import PartyCard from '../PartyCard/PartyCard';

const LiveDashboard = ({
  parties,
  selectedParty,
  handleInputChange,
  handleAddPartyCard,
  handleRemovePartyCard,
  handleRemoveAllPartyCards,
  partyDetails,
  loading,
  countdown,
}) => (
  <>
    <PartySelector
      parties={parties}
      selectedParty={selectedParty}
      handleInputChange={handleInputChange}
      handleAddPartyCard={handleAddPartyCard}
      handleRemovePartyCard={handleRemovePartyCard}
      handleRemoveAllPartyCards={handleRemoveAllPartyCards}
    />
    {partyDetails.map((party) => (
      <PartyCard
        key={party.partyId}
        party={party}
        loading={loading}
        countdown={countdown}
      />
    ))}
  </>
);

export default LiveDashboard;
