import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  CircularProgress,
  Paper,
  Card,
  CardContent,
  Snackbar,
} from '@mui/material';
import {
  fetchPartyDetails,
  fetchTotalSoldItems,
  fetchMostSold,
  fetchLowestStock,
} from '../../utils/api';
import { usePermissions } from '../../contexts/Permissions/PermissionsContext';
import { BlinkingDot, GlobalTitle } from '../../utils/global';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PublicIcon from '@mui/icons-material/Public';
import StockModal from './StockModal/StockModal';
import ChartsModal from './ChartsModal/ChartsModal';
import SalesModal from './SalesModal/SalesModal';
import EmployeeManagementModal from './EmployeeManagementModal/EmployeeManagementModal';

const PartyDetails = () => {
  const { partyId } = useParams();
  const [partyDetails, setPartyDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshInterval] = useState(60);
  const [countdown, setCountdown] = useState(refreshInterval);
  const [totalSoldItems, setTotalSoldItems] = useState(0);
  const [totalMoney, setTotalMoney] = useState(0);
  const [mostSold, setMostSold] = useState({});
  const [lowestStock, setLowestStock] = useState({});
  const [partyEnded, setPartyEnded] = useState(false);
  const { permissions } = usePermissions();
  const [openStockModal, setOpenStockModal] = useState(false);
  const [openChartsModal, setOpenChartsModal] = useState(false);
  const [openSalesModal, setOpenSalesModal] = useState(false);
  const [openEmployeesModal, setOpenEmployeesModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const hasPartyEnded = (endDate, endHour) => {
    const [endYear, endMonth, endDay] = endDate.split('-');
    const [endHourHour, endHourMinute] = endHour.split(':');
    const partyEndDateTime = new Date(
      endYear,
      endMonth - 1,
      endDay,
      endHourHour,
      endHourMinute,
    );
    return new Date() > partyEndDateTime;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const partyDetailsData = await fetchPartyDetails(partyId);
        setPartyDetails(partyDetailsData);

        if (hasPartyEnded(partyDetailsData.endDate, partyDetailsData.endHour)) {
          setPartyEnded(true);
          clearInterval(intervalId);
          return;
        }

        const solds = await fetchTotalSoldItems(partyId);
        const totalItems = solds.reduce(
          (acc, sold) => acc + sold.quantitySold,
          0,
        );
        const totalMoney = solds.reduce((acc, sold) => acc + sold.value, 0);

        setTotalSoldItems(totalItems);
        setTotalMoney(totalMoney);
        setMostSold(await fetchMostSold(partyId));
        setLowestStock(await fetchLowestStock(partyId));
      } catch (error) {
        console.error('Erro ao obter dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, refreshInterval * 1000);

    return () => clearInterval(intervalId);
  }, [partyId, refreshInterval]);

  useEffect(() => {
    if (!partyEnded) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) =>
          prevCountdown > 0 ? prevCountdown - 1 : refreshInterval,
        );
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [refreshInterval, partyEnded]);

  return (
    <Paper elevation={3} sx={{ padding: '15px', borderRadius: '10px' }}>
      <GlobalTitle title={`${partyDetails?.nameParty}`} />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="body1" gutterBottom>
            Data inicio: {partyDetails?.startDate} || {partyDetails?.startHour}h
          </Typography>
          <Typography variant="body1" gutterBottom>
            Data fim: {partyDetails?.endDate} || {partyDetails?.endHour}h
          </Typography>
          <Typography variant="body1" gutterBottom>
            Local: {partyDetails?.local}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Total de Funcionários: {partyDetails?.employees?.length || 0}
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            {permissions.stocks?.view && (
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<InventoryIcon />}
                  onClick={() => setOpenStockModal(true)}
                >
                  Estoque
                </Button>
              </Grid>
            )}
            {permissions.stocks?.view && (
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<ShowChartIcon />}
                  onClick={() => setOpenChartsModal(true)}
                >
                  Gráficos
                </Button>
              </Grid>
            )}
            {permissions.solds?.view && (
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<LocalMallIcon />}
                  onClick={() => setOpenSalesModal(true)}
                >
                  Vendas
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<PublicIcon />}
                component={Link}
                to={`/public/${partyId}`}
              >
                Página Pública
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setOpenEmployeesModal(true)}
              >
                Gerenciar Funcionários
              </Button>
            </Grid>
          </Grid>
          <Card sx={{ marginTop: 4 }}>
            <CardContent>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="h6">
                    {partyEnded
                      ? 'Detalhes da Festa'
                      : 'Detalhes Em Tempo Real'}
                  </Typography>
                </Grid>
                {!partyEnded && (
                  <Grid item>
                    <BlinkingDot />
                  </Grid>
                )}
              </Grid>
              <Typography sx={{ marginTop: 2 }} variant="body1" gutterBottom>
                Total de Vendas: {totalSoldItems}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Receita Total: R$ {totalMoney.toLocaleString('pt-BR')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Item Mais Vendido: {mostSold._id}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Item com Menor Estoque: {lowestStock.nameProduct} (Quantidade:{' '}
                {lowestStock.quantityRemaining})
              </Typography>
              {!partyEnded && (
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Atualização automática em: {countdown} segundos
                </Typography>
              )}
            </CardContent>
          </Card>
        </>
      )}
      <StockModal
        open={openStockModal}
        onClose={() => setOpenStockModal(false)}
      />
      <ChartsModal
        open={openChartsModal}
        onClose={() => setOpenChartsModal(false)}
      />
      <SalesModal
        open={openSalesModal}
        onClose={() => setOpenSalesModal(false)}
      />
      <EmployeeManagementModal
        open={openEmployeesModal}
        onClose={() => setOpenEmployeesModal(false)}
        partyId={partyId}
        setSuccessMessage={setSuccessMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
    </Paper>
  );
};

export default PartyDetails;
