// src/components/Logout/Logout.js

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../contexts/Permissions/PermissionsContext';

const Logout = () => {
  const { setTokenLocal } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = () => {
      localStorage.removeItem('token');
      setTokenLocal(false);
      navigate('/login');
    };

    handleLogout();
  }, [setTokenLocal, navigate]);

  return null;
};

export default Logout;
