import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchPermissions } from '../../utils/api';

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [tokenLocal, setTokenLocal] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const isTokenValid = () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setTokenLocal(false);
        return;
      }
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp > currentTime) {
        setTokenLocal(true);
      } else {
        setTokenLocal(false);
      }
    } catch (error) {
      setTokenLocal(false);
    }
  };

  const fetchPermissionsData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setLoading(false);
      return;
    }

    const decodedToken = jwtDecode(token);
    const groupId = decodedToken.group;

    if (!groupId) {
      setLoading(false);
      return;
    }

    try {
      const permissionsData = await fetchPermissions(groupId);
      setPermissions(permissionsData);
      setLoading(false);
    } catch (error) {
      setTokenLocal(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    isTokenValid();
  }, [tokenLocal]);

  useEffect(() => {
    fetchPermissionsData();
  }, []);

  useEffect(() => {
    const publicRoutes = [
      '/login',
      '/reset-password',
      '/new-password',
      '/public',
    ];
    const isPublicRoute = publicRoutes.some((route) =>
      location.pathname.startsWith(route),
    );
    if (!tokenLocal && !isPublicRoute) {
      navigate('/login');
    }
  }, [tokenLocal, navigate, location]);

  return (
    <PermissionsContext.Provider
      value={{ permissions, loading, tokenLocal, setTokenLocal, isTokenValid }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
