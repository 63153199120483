import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Grid,
} from '@mui/material';

const UserFormDialog = ({
  open,
  onClose,
  onSubmit,
  newUser,
  handleInputChange,
  isCompany,
  setIsCompany,
  groups,
  editMode,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>{editMode ? 'Editar Usuario' : 'Novo Usuario'}</DialogTitle>
    <DialogContent>
      <form onSubmit={onSubmit}>
        <Box sx={{ mb: 2 }}>
          <TextField
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            value={newUser.username}
            onChange={handleInputChange}
            required
          />
        </Box>
        {!editMode && (
          <Box sx={{ mb: 2 }}>
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={newUser.password}
              onChange={handleInputChange}
              required
            />
          </Box>
        )}
        <Box sx={{ mb: 2 }}>
          <TextField
            margin="dense"
            name="name"
            label="Nome"
            type="text"
            fullWidth
            value={newUser.name}
            onChange={handleInputChange}
            required
          />
        </Box>
        <Grid item sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="group-label">Grupo</InputLabel>
            <Select
              labelId="group-label"
              label="Grupo"
              name="group"
              value={newUser.group}
              onChange={handleInputChange}
              required
            >
              {groups.map((group) => (
                <MenuItem key={group._id} value={group._id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Box sx={{ mb: 2 }}>
          <TextField
            margin="dense"
            name="cel"
            label="Celular"
            type="text"
            fullWidth
            value={newUser.cel}
            onChange={handleInputChange}
            required
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={handleInputChange}
            required
          />
        </Box>
        {!editMode && (
          <Box sx={{ mb: 2 }}>
            {isCompany ? (
              <TextField
                margin="dense"
                name="cnpj"
                label="CNPJ"
                type="text"
                fullWidth
                value={newUser.cnpj}
                onChange={handleInputChange}
                required
              />
            ) : (
              <TextField
                margin="dense"
                name="cpf"
                label="CPF"
                type="text"
                fullWidth
                value={newUser.cpf}
                onChange={handleInputChange}
                required
              />
            )}
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setIsCompany((prev) => !prev);
                handleInputChange({ target: { name: 'cpf', value: '' } });
                handleInputChange({ target: { name: 'cnpj', value: '' } });
              }}
              sx={{ marginTop: 2 }}
            >
              {isCompany ? 'Mudar Para Colaborador' : 'Mudar Para Empresa'}
            </Button>
          </Box>
        )}
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button type="submit" variant="outlined" color="primary">
            {editMode ? 'Salvar' : 'Cadastrar'}
          </Button>
        </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
);

export default UserFormDialog;
