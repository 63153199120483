import React from 'react';
import { Typography, Card, CardContent, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { usePermissions } from '../../../../contexts/Permissions/PermissionsContext';

const StockItemCard = ({ item, onEdit, onDelete }) => {
  const { permissions } = usePermissions();

  return (
    <Card sx={{ marginTop: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {item.nameProduct}
        </Typography>
        <Typography variant="body1">
          Quantidade: {item.qtd} {item.qtdUnit}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Preço de Compra: R$ {parseFloat(item.purchasePrice).toFixed(2)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Preço de Venda: R$ {parseFloat(item.sellingPrice).toFixed(2)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Volume: {item.volume} {item.volumeUnit}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Categoria: {item.category}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Observações: {item.observations}
        </Typography>
        <Box sx={{ marginTop: 1 }}>
          {permissions.stocks?.edit && (
            <IconButton
              aria-label="Editar"
              sx={{ marginRight: 1 }}
              onClick={() => onEdit(item._id)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissions.stocks?.delete && (
            <IconButton aria-label="Deletar" onClick={() => onDelete(item._id)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default StockItemCard;
