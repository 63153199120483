import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { PermissionsProvider } from './contexts/Permissions/PermissionsContext';
import { GlobalProvider } from './contexts/Global/GlobalContext';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import Home from './components/Home/Home';
import Partys from './components/Partys/Partys';
import PartyDetails from './components/PartyDetails/PartyDetails';
import RotaProtegida from './components/RotaProtegida/RotaProtegida';
import NotFound from './components/NotFound/NotFound';
import Dashboard from './components/Dashboard/Dashboard';
import ResetPass from './components/ResetPass/ResetPass';
import NewPass from './components/ResetPass/NewPass';
import PublicParty from './components/PublicParty/PublicParty';
import UserManagement from './components/UserManagement/UserManagement';
import WalletManager from './components/WalletManager/WalletManager';
import { lightTheme, darkTheme } from './theme';
import { SpeedInsights } from '@vercel/speed-insights/react';
import CategoryManager from './components/CategoryManager/CategoryManager';

const App = () => {
  const [mode, setMode] = useState(localStorage.getItem('themeMode') || 'dark');
  const currentTheme = mode === 'dark' ? darkTheme : lightTheme;

  const toggleTheme = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    }
  }, []);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <SpeedInsights />
      <Router>
        <PermissionsProvider>
          <GlobalProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/reset-password" element={<ResetPass />} />
              <Route path="/new-password" element={<NewPass />} />

              <Route
                path="/"
                element={
                  <RotaProtegida
                    element={
                      <Dashboard toggleTheme={toggleTheme} mode={mode} />
                    }
                    permissionsRequired={['home.view']}
                  />
                }
              >
                <Route
                  index
                  element={
                    <RotaProtegida
                      element={<Home />}
                      permissionsRequired={['home.view']}
                    />
                  }
                />
                <Route
                  path="partys"
                  element={
                    <RotaProtegida
                      element={<Partys />}
                      permissionsRequired={['parties.view']}
                    />
                  }
                />
                <Route
                  path="party/:partyId"
                  element={
                    <RotaProtegida
                      element={<PartyDetails />}
                      permissionsRequired={['parties.view']}
                    />
                  }
                />
                <Route
                  path="userManagement"
                  element={
                    <RotaProtegida
                      element={<UserManagement />}
                      permissionsRequired={['userGroups.view']}
                    />
                  }
                />

                <Route
                  path="walletManager"
                  element={
                    <RotaProtegida
                      element={<WalletManager />}
                      permissionsRequired={['cards.view']}
                    />
                  }
                />

                <Route
                  path="categoryManager"
                  element={
                    <RotaProtegida
                      element={<CategoryManager />}
                      permissionsRequired={['categories.view']}
                    />
                  }
                />
              </Route>

              <Route path="/public/:partyId" element={<PublicParty />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </GlobalProvider>
        </PermissionsProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
