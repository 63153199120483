import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  Modal,
  Paper,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
} from '@mui/material';
import {
  Add,
  CreditCard,
  Remove,
  Close,
  QrCode,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  addCardToWallet,
  rechargeWallet,
  withdrawFromWallet,
  getWallet,
  generateCardQRCode,
  transferBalance,
} from '../../utils/api';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const WalletManager = () => {
  const [userIdOrCpf, setUserIdOrCpf] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [recipientIdOrCpf, setRecipientIdOrCpf] = useState('');
  const [wallet, setWallet] = useState({ balance: 0, cards: [] });
  const [tabIndex, setTabIndex] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [qrCodes, setQrCodes] = useState([]);
  const [expandCardQr, setExpandCardQr] = useState(null);
  const [actionType, setActionType] = useState(''); // Adicionada definição do estado actionType

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const handleConfirmationOpen = (type) => {
    setActionType(type);
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = () => setConfirmationOpen(false);

  const fetchWallet = async () => {
    try {
      const response = await getWallet(userIdOrCpf);
      if (response) {
        setWallet(response.wallet);
        setSuccess('Carteira obtida com sucesso!');
        setError(''); // Clear error state
        setOpenSnackbar(true);
      } else {
        throw new Error('Usuário não encontrado.');
      }
    } catch (error) {
      setError(error.message || 'Erro ao obter carteira');
      setSuccess(''); // Clear success state
      setOpenSnackbar(true);
    }
  };

  const handleAddCardToWallet = async () => {
    try {
      await addCardToWallet(userIdOrCpf, cardNumber);
      setSuccess('Cartão adicionado com sucesso!');
      setError(''); // Clear error state
      fetchWallet();
      setOpenSnackbar(true);
    } catch (error) {
      setError(error.response?.data?.error || 'Erro ao adicionar cartão');
      setSuccess(''); // Clear success state
      setOpenSnackbar(true);
    }
    handleConfirmationClose();
  };

  const handleRechargeWallet = async () => {
    try {
      await rechargeWallet(userIdOrCpf, parseFloat(amount));
      setSuccess('Recarga realizada com sucesso!');
      setError(''); // Clear error state
      fetchWallet();
      setOpenSnackbar(true);
    } catch (error) {
      setError(error.response?.data?.error || 'Erro ao recarregar carteira');
      setSuccess(''); // Clear success state
      setOpenSnackbar(true);
    }
    handleConfirmationClose();
  };

  const handleWithdrawFromWallet = async () => {
    try {
      await withdrawFromWallet(userIdOrCpf, cardNumber, parseFloat(amount));
      setSuccess('Retirada realizada com sucesso!');
      setError(''); // Clear error state
      fetchWallet();
      setOpenSnackbar(true);
    } catch (error) {
      setError(error.response?.data?.error || 'Erro ao retirar da carteira');
      setSuccess(''); // Clear success state
      setOpenSnackbar(true);
    }
    handleConfirmationClose();
  };

  const handleTransferBalance = async () => {
    try {
      await transferBalance(userIdOrCpf, recipientIdOrCpf, parseFloat(amount));
      setSuccess('Transferência realizada com sucesso!');
      setError(''); // Clear error state
      fetchWallet();
      setOpenSnackbar(true);
    } catch (error) {
      setError(error.response?.data?.error || 'Erro ao transferir saldo');
      setSuccess(''); // Clear success state
      setOpenSnackbar(true);
    }
    handleConfirmationClose();
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleApplyCard = (card) => {
    setCardNumber(card.cardNumber);
    handleModalClose();
    setSuccess('Cartão aplicado com sucesso!');
    setError(''); // Clear error state
    setOpenSnackbar(true);
  };

  const handleGenerateQrCodes = async (cardNumber) => {
    try {
      const response = await generateCardQRCode(userIdOrCpf, cardNumber);
      setQrCodes(response.qrCodes);
      setExpandCardQr(cardNumber); // Expande o QR code do cartão
    } catch (error) {
      setError(error.response?.data?.error || 'Erro ao gerar QR code');
      setSuccess(''); // Clear success state
      setOpenSnackbar(true);
    }
  };

  const handleConfirmAction = () => {
    if (actionType === 'addCard') {
      handleAddCardToWallet();
    } else if (actionType === 'recharge') {
      handleRechargeWallet();
    } else if (actionType === 'withdraw') {
      handleWithdrawFromWallet();
    } else if (actionType === 'transfer') {
      handleTransferBalance();
    }
  };

  const handleExpandClick = (cardNumber) => {
    setExpandCardQr(expandCardQr === cardNumber ? null : cardNumber);
  };

  return (
    <Paper elevation={3} sx={{ padding: '15px', borderRadius: '10px' }}>
      <Typography variant="h4" gutterBottom>
        Gerenciamento de Carteira
      </Typography>
      <Box mb={2}>
        <TextField
          label="User ID ou CPF"
          value={userIdOrCpf}
          onChange={(e) => setUserIdOrCpf(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="outlined" color="primary" onClick={fetchWallet}>
          Obter Carteira
        </Button>
      </Box>

      <Typography variant="h6" gutterBottom>
        Saldo da Carteira: {wallet.balance.toFixed(2)}
      </Typography>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="wallet management tabs"
        centered
      >
        <Tab label="Adicionar Cartão" />
        <Tab label="Recarregar Carteira" />
        <Tab label="Retirar da Carteira" />
        <Tab label="Transferir Saldo" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <Paper elevation={4} style={{ padding: '16px' }}>
          <Box mb={2}>
            <TextField
              label="Número do Cartão"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              onClick={() => handleConfirmationOpen('addCard')}
            >
              Adicionar Cartão
            </Button>
          </Box>
        </Paper>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <Paper elevation={4} style={{ padding: '16px' }}>
          <Box mb={2}>
            <TextField
              label="Valor"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              margin="normal"
              type="number"
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CreditCard />}
              onClick={() => handleConfirmationOpen('recharge')}
            >
              Recarregar Carteira
            </Button>
          </Box>
        </Paper>
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <Paper elevation={4} style={{ padding: '16px' }}>
          <Box mb={2}>
            <TextField
              label="Número do Cartão"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Valor"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              margin="normal"
              type="number"
            />
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Remove />}
              onClick={() => handleConfirmationOpen('withdraw')}
            >
              Retirar da Carteira
            </Button>
          </Box>
        </Paper>
      </TabPanel>

      <TabPanel value={tabIndex} index={3}>
        <Paper elevation={4} style={{ padding: '16px' }}>
          <Box mb={2}>
            <TextField
              label="CPF ou ID do Destinatário"
              value={recipientIdOrCpf}
              onChange={(e) => setRecipientIdOrCpf(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Valor"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              margin="normal"
              type="number"
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CreditCard />}
              onClick={() => handleConfirmationOpen('transfer')}
            >
              Transferir Saldo
            </Button>
          </Box>
        </Paper>
      </TabPanel>

      <Box mt={2}>
        <Button variant="outlined" color="primary" onClick={handleModalOpen}>
          Visualizar Cartões
        </Button>
      </Box>

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh', // Limita a altura do modal
            overflowY: 'auto', // Adiciona rolagem vertical
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Selecione um Cartão
            </Typography>
            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            {wallet.cards.map((card, index) => (
              <Grid item xs={12} key={index}>
                <Paper elevation={2} style={{ padding: '16px' }}>
                  <Typography>Número do Cartão: {card.cardNumber}</Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CreditCard />}
                    onClick={() => handleApplyCard(card)}
                  >
                    Aplicar
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<QrCode />}
                    onClick={() => handleGenerateQrCodes(card.cardNumber)}
                  >
                    Gerar QR Code
                  </Button>
                  <IconButton
                    onClick={() => handleExpandClick(card.cardNumber)}
                  >
                    {expandCardQr === card.cardNumber ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </IconButton>
                  <Collapse
                    in={expandCardQr === card.cardNumber}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box mt={2}>
                      {qrCodes
                        .filter((qr) => qr.cardNumber === card.cardNumber)
                        .map((qr, qrIndex) => (
                          <Box key={qrIndex} mb={2}>
                            <img
                              src={qr.qrImageUrl}
                              alt={`QR Code ${qrIndex}`}
                              style={{ width: '100%' }}
                            />
                          </Box>
                        ))}
                    </Box>
                  </Collapse>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Modal>

      <Dialog
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            {actionType === 'addCard' &&
              `Você está adicionando o cartão número ${cardNumber} ao CPF ${userIdOrCpf}.`}
            {actionType === 'recharge' &&
              `Você está recarregando R$ ${amount} na carteira do CPF ${userIdOrCpf}.`}
            {actionType === 'withdraw' &&
              `Você está retirando R$ ${amount} da carteira do CPF ${userIdOrCpf}, associado ao cartão ${cardNumber}.`}
            {actionType === 'transfer' &&
              `Você está transferindo R$ ${amount} da carteira do CPF ${userIdOrCpf} para a carteira do CPF ${recipientIdOrCpf}.`}
            Deseja continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmAction} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {error ? (
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        ) : (
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {success}
          </Alert>
        )}
      </Snackbar>
    </Paper>
  );
};

export default WalletManager;
