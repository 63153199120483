// context.js
import React, { createContext, useContext } from 'react';
import { fetchSolds } from '../../utils/api';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  return (
    <GlobalContext.Provider value={{ fetchSolds }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
