import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Box,
  Grid,
} from '@mui/material';
import Countdown from 'react-countdown';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Icon, Style } from 'ol/style';
import { fetchPartyDetailsPublic, fetchCoordinates } from '../../utils/api';
import Logo from '../../imgs/logoDark.png';
import 'ol/ol.css';

const parseDateTime = (dateStr, timeStr) => {
  if (!dateStr || !timeStr) return null;

  const [year, month, day] = dateStr.split('-');
  const [hours, minutes] = timeStr.split(':');
  return new Date(year, month - 1, day, hours, minutes);
};

const countdownRenderer = ({ days, hours, minutes, seconds }) => (
  <Typography variant="h6">
    {days} dias, {hours} horas, {minutes} minutos, {seconds} segundos
  </Typography>
);

const PublicParty = () => {
  const { partyId } = useParams();
  const [partyDetails, setPartyDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const mapRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const partyDetailsData = await fetchPartyDetailsPublic(partyId);
        setPartyDetails(partyDetailsData);

        if (partyDetailsData && partyDetailsData.local) {
          const { lat, lng } = await fetchCoordinates(partyDetailsData.local);
          setCoordinates([lng, lat]);
        }
      } catch (error) {
        console.error('Erro ao obter dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [partyId]);

  useEffect(() => {
    if (coordinates && mapRef.current) {
      const map = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat(coordinates),
          zoom: 16,
        }),
      });

      const iconFeature = new Feature({
        geometry: new Point(fromLonLat(coordinates)),
      });

      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'https://maps.google.com/mapfiles/kml/pushpin/red-pushpin.png',
        }),
      });

      iconFeature.setStyle(iconStyle);

      const vectorSource = new VectorSource({
        features: [iconFeature],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      map.addLayer(vectorLayer);
    }
  }, [coordinates]);

  const countdownDate = parseDateTime(
    partyDetails?.startDate,
    partyDetails?.startHour,
  );

  return (
    <Box className="mobile-scroll" sx={{ py: 1 }}>
      <Container maxWidth="lg">
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={1}
                mt={1}
              >
                <img
                  src={Logo}
                  alt="logo da paynparty"
                  style={{ height: '60px' }}
                />
              </Box>
              <Typography variant="h4" gutterBottom align="center">
                {partyDetails?.nameParty || 'Carregando...'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    <strong>Data inicio:</strong> {partyDetails?.startDate} às{' '}
                    {partyDetails?.startHour}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    <strong>Data fim:</strong> {partyDetails?.endDate} às{' '}
                    {partyDetails?.endHour}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    <strong>Local:</strong> {partyDetails?.local}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    <strong>Lineup:</strong> {partyDetails?.lineup}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    <strong>Descrição:</strong> {partyDetails?.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {countdownDate && (
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="body1" gutterBottom>
                      <strong>Contagem regressiva:</strong>{' '}
                      <Countdown
                        date={countdownDate}
                        renderer={countdownRenderer}
                      />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              {coordinates && (
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: '300px', md: '400px' },
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      <div
                        ref={mapRef}
                        style={{ width: '100%', height: '100%' }}
                      ></div>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default PublicParty;
