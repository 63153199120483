import React, { useState, useEffect, useReducer } from 'react';
import { Typography, Grid, Button, Snackbar, Alert, Box, Modal, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import StockItemCard from './StockItemCard/StockItemCard';
import StockModal from './StockModal/StockModal';
import ItemListModal from './ItemListModal/ItemListModal';
import { fetchStock, addStockItem, updateStockItem, deleteStockItem } from '../../../utils/api';
import { usePermissions } from '../../../contexts/Permissions/PermissionsContext';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';

const initialState = {
  stock: [],
  newStockItems: [],
  currentItem: { nameProduct: '', category: '', qtd: '', qtdUnit: '', volume: '', volumeUnit: '', purchasePrice: '', sellingPrice: '', observations: '' },
  snackbar: { open: false, message: '', severity: 'error' },
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_STOCK':
      return { ...state, stock: action.payload };
    case 'ADD_NEW_STOCK_ITEM':
      return { ...state, newStockItems: [...state.newStockItems, action.payload] };
    case 'REMOVE_STOCK_ITEM':
      return { ...state, newStockItems: state.newStockItems.filter((_, index) => index !== action.payload) };
    case 'UPDATE_CURRENT_ITEM':
      return { ...state, currentItem: { ...state.currentItem, ...action.payload } };
    case 'RESET_CURRENT_ITEM':
      return { ...state, currentItem: initialState.currentItem };
    case 'SET_SNACKBAR':
      return { ...state, snackbar: { open: true, ...action.payload } };
    case 'CLOSE_SNACKBAR':
      return { ...state, snackbar: initialState.snackbar };
    default:
      return state;
  }
}

const ListStock = () => {
  const { partyId } = useParams();
  const [open, setOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [itemListModalOpen, setItemListModalOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { stock, newStockItems, currentItem, snackbar } = state;
  const [productIdToEdit, setProductIdToEdit] = useState(null);
  const { permissions } = usePermissions();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStock(partyId);
        dispatch({ type: 'SET_STOCK', payload: data });
      } catch (error) {
        console.error('Erro ao buscar dados do estoque:', error);
      }
    };

    fetchData();
  }, [partyId]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditModalOpen = (productId) => {
    const itemToEdit = stock?.party?.products?.find(item => item._id === productId);

    if (!itemToEdit) {
      console.error('Item não encontrado para edição');
      return;
    }

    dispatch({ type: 'UPDATE_CURRENT_ITEM', payload: { ...itemToEdit } });
    setProductIdToEdit(productId);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    dispatch({ type: 'RESET_CURRENT_ITEM' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'UPDATE_CURRENT_ITEM', payload: { [name]: value } });
  };

  const addItem = () => {
    const { nameProduct, category, qtd, qtdUnit, volume, volumeUnit, purchasePrice, sellingPrice, observations } = currentItem;

    if (!nameProduct || !category || !qtd || !volume || !qtdUnit || !volumeUnit || !purchasePrice || !sellingPrice || !observations) {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: 'Por favor, preencha todos os campos antes de adicionar o item.', severity: 'error' } });
      return;
    }

    dispatch({ type: 'ADD_NEW_STOCK_ITEM', payload: { ...currentItem, qtd: Number(qtd), volume: Number(volume), purchasePrice: Number(purchasePrice), sellingPrice: Number(sellingPrice) } });
    dispatch({ type: 'RESET_CURRENT_ITEM' });
    dispatch({ type: 'SET_SNACKBAR', payload: { message: `Item ${nameProduct} adicionado à lista.`, severity: 'success' } });
  };

  const removeItem = (index) => {
    dispatch({ type: 'REMOVE_STOCK_ITEM', payload: index });
  };

  const handleAddStock = async () => {
    try {
      const data = {
        partyId,
        products: newStockItems
      };

      await addStockItem(data);
      setOpen(false);
      setItemListModalOpen(false);
      dispatch({ type: 'RESET_CURRENT_ITEM' });
      fetchStockData();
    } catch (error) {
      console.error('Erro ao cadastrar estoque:', error);
    }
  };

  const handleUpdateStock = async () => {
    try {
      const data = {
        nameProduct: currentItem.nameProduct,
        category: currentItem.category,
        qtd: currentItem.qtd,
        qtdUnit: currentItem.qtdUnit,
        volume: currentItem.volume,
        volumeUnit: currentItem.volumeUnit,
        purchasePrice: currentItem.purchasePrice,
        sellingPrice: currentItem.sellingPrice,
        observations: currentItem.observations
      };

      await updateStockItem(partyId, productIdToEdit, data);
      setShowEditModal(false);
      dispatch({ type: 'RESET_CURRENT_ITEM' });
      fetchStockData();
    } catch (error) {
      console.error('Erro ao atualizar item de estoque:', error);
    }
  };

  const handleDeleteItem = async (productId) => {
    try {
      await deleteStockItem(partyId, productId);
      fetchStockData();
    } catch (error) {
      console.error('Erro ao excluir item de estoque:', error);
    }
  };

  const handleCloseSnackbar = () => {
    dispatch({ type: 'CLOSE_SNACKBAR' });
  };

  const fetchStockData = async () => {
    try {
      const data = await fetchStock(partyId);
      dispatch({ type: 'SET_STOCK', payload: data });
    } catch (error) {
      console.error('Erro ao buscar dados do estoque:', error);
    }
  };

  const handleImportExcel = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(firstSheet);

      const importedItems = jsonData.map(item => ({
        nameProduct: item.nameProduct || '',
        category: item.category || '',
        qtd: item.qtd || '',
        qtdUnit: item.qtdUnit || '',
        volume: item.volume || '',
        volumeUnit: item.volumeUnit || '',
        purchasePrice: item.purchasePrice || '',
        sellingPrice: item.sellingPrice || '',
        observations: item.observations || ''
      }));

      importedItems.forEach(item => {
        dispatch({ type: 'ADD_NEW_STOCK_ITEM', payload: item });
      });
    };
    reader.readAsArrayBuffer(file);
    setItemListModalOpen(true);
  };

  const handleItemListModalOpen = () => {
    setItemListModalOpen(true);
  };

  const handleItemListModalClose = () => {
    setItemListModalOpen(false);
  };

  return (
    <>
      {permissions.stocks?.edit && (
        <>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
            sx={{ m: '0 10px 10px 0' }}
          >
            Cadastrar Estoque
          </Button>

          <Button
            variant="outlined"
            color="primary"
            component="label"
            startIcon={<UploadIcon />}
            sx={{ m: '0 10px 10px 0' }}
          >
            Importar Excel
            <input
              type="file"
              hidden
              onChange={handleImportExcel}
            />
          </Button>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<VisibilityIcon />}
            onClick={handleItemListModalOpen}
            sx={{ m: '0 10px 10px 0' }}
          >
            Visualizar Itens
          </Button>
        </>
      )}
      <Grid container spacing={2}>
        {stock?.party?.products?.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
            <StockItemCard item={item} onEdit={handleEditModalOpen} onDelete={handleDeleteItem} />
          </Grid>
        ))}
      </Grid>
      <StockModal
        open={open}
        handleClose={handleClose}
        handleInputChange={handleInputChange}
        currentItem={currentItem}
        addItem={addItem}
        handleImportExcel={handleImportExcel}
        handleItemListModalOpen={handleItemListModalOpen}
      />
      <ItemListModal
        open={itemListModalOpen}
        handleClose={handleItemListModalClose}
        newStockItems={newStockItems}
        removeItem={removeItem}
        handleAddStock={handleAddStock}
      />
      <Snackbar open={snackbar.open} autoHideDuration={1500} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>{snackbar.message}</Alert>
      </Snackbar>
      <Modal open={showEditModal} onClose={handleEditModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%' },
          bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4
        }}>
          <Typography variant="h6" component="h2" gutterBottom>Editar Item de Estoque</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nome do Produto"
                name="nameProduct"
                variant="outlined"
                fullWidth
                value={currentItem.nameProduct}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Categoria"
                name="category"
                variant="outlined"
                fullWidth
                value={currentItem.category}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Quantidade"
                name="qtd"
                variant="outlined"
                type="number"
                fullWidth
                placeholder='Ex: 10'
                value={currentItem.qtd}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="qtd-unit-label">Unidade</InputLabel>
                <Select
                  labelId="qtd-unit-label"
                  name="qtdUnit"
                  value={currentItem.qtdUnit}
                  onChange={handleInputChange}
                  label="Unidade"
                >
                  <MenuItem value="Latas">Latas</MenuItem>
                  <MenuItem value="Unidades">Unidades</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Volume"
                name="volume"
                variant="outlined"
                fullWidth
                placeholder='Ex: 200'
                value={currentItem.volume}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="volume-unit-label">Unidade</InputLabel>
                <Select
                  labelId="volume-unit-label"
                  name="volumeUnit"
                  value={currentItem.volumeUnit}
                  onChange={handleInputChange}
                  label="Unidade"
                >
                  <MenuItem value="ml">ml</MenuItem>
                  <MenuItem value="Litros">Litros</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Preço de Compra"
                name="purchasePrice"
                variant="outlined"
                type="number"
                fullWidth
                value={currentItem.purchasePrice}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Preço de Venda"
                name="sellingPrice"
                variant="outlined"
                type="number"
                fullWidth
                value={currentItem.sellingPrice}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observações"
                name="observations"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={currentItem.observations}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleUpdateStock}
                fullWidth
              >
                Atualizar Item
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ListStock;
