import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  Modal,
  Paper,
  Grid,
  IconButton,
} from '@mui/material';
import { createCategory, searchCategory, editCategory } from '../../utils/api';
import { Edit, Close } from '@mui/icons-material';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const CategoryManager = () => {
  const [name, setName] = useState('');
  const [img, setImg] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedCategory(null);
    setName('');
    setImg('');
  };

  const fetchCategories = async () => {
    try {
      const response = await searchCategory();
      setCategories(response);
      setSuccess('Categorias obtidas com sucesso!');
      setOpenSnackbar(true);
    } catch (error) {
      setError('Erro ao obter categorias');
      setOpenSnackbar(true);
    }
  };

  const handleCreateCategory = async () => {
    try {
      await createCategory({ name, img });
      setSuccess('Categoria criada com sucesso!');
      fetchCategories();
      setOpenSnackbar(true);
    } catch (error) {
      setError('Erro ao criar categoria');
      setOpenSnackbar(true);
    }
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setName(category.name);
    setImg(category.img);
    handleModalOpen();
  };

  const handleSaveCategory = async () => {
    try {
      await editCategory(selectedCategory._id, { name, img });
      setSuccess('Categoria editada com sucesso!');
      fetchCategories();
      handleModalClose();
      setOpenSnackbar(true);
    } catch (error) {
      setError('Erro ao editar categoria');
      setOpenSnackbar(true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === 1) {
      fetchCategories();
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: '15px', borderRadius: '10px' }}>
      <Typography variant="h4" gutterBottom>
        Gerenciamento de Categorias
      </Typography>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="category management tabs"
        centered
      >
        <Tab label="Adicionar Categoria" />
        <Tab label="Visualizar Categorias" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <Box mb={2}>
          <TextField
            label="Nome da Categoria"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Imagem da Categoria"
            value={img}
            onChange={(e) => setImg(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCategory}
          >
            Adicionar Categoria
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <Grid container spacing={2}>
          {categories.map((category, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper elevation={2} sx={{ padding: '16px' }}>
                <Typography variant="h6">{category.name}</Typography>
                <img
                  src={category.img}
                  alt={category.name}
                  style={{
                    width: '100%',
                    height: 'auto',
                    marginBottom: '10px',
                  }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<Edit />}
                  onClick={() => handleEditCategory(category)}
                >
                  Editar
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </TabPanel>

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Editar Categoria
            </Typography>
            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Box>
          <TextField
            label="Nome da Categoria"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Imagem da Categoria"
            value={img}
            onChange={(e) => setImg(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveCategory}
            sx={{ mt: 2 }}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleModalClose}
            sx={{ mt: 2 }}
          >
            Cancelar
          </Button>
        </Paper>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {error ? (
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        ) : (
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {success}
          </Alert>
        )}
      </Snackbar>
    </Paper>
  );
};

export default CategoryManager;
