import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import ListSolds from '../ListSolds';

const SalesModal = ({ open, onClose }) => (
  <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
    <DialogTitle>Vendas</DialogTitle>
    <DialogContent>
      <ListSolds />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Fechar</Button>
    </DialogActions>
  </Dialog>
);

export default SalesModal;
